import React from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { Link, Modal } from 'src/components'
import { sc } from 'src/styles'
import { useHistory } from 'src/helpers/hooks'
import { Routes } from 'src/constants'

type Props = {
  isOpen: boolean
  setOpen: (state: boolean) => void
  title: string
}

export default ({ isOpen, setOpen, title }: Props) => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const onSelectKeys = () => push(Routes.TrackKeys)
  const onSelectItems = () => push(Routes.TrackItems)
  const onSelectQuests = () => push(Routes.TrackQuests)

  return (
    <Container isOpen={isOpen} setOpen={setOpen} title={title}>
      <Question>{t('modals.faq.how.title')}</Question>
      <Answer>
        <div>
          <span>{t('modals.faq.how.increment')}</span>
        </div>

        <div>
          <span>{t('modals.faq.how.decrement')}</span>
        </div>

        <div>
          <Trans
            i18nKey="modals.faq.how.quest_items"
            components={{
              navto: <StyledLink onClick={onSelectItems} />,
              fir: <FIR />,
            }}
          />
        </div>

        <div>
          <Trans
            i18nKey="modals.faq.how.quest_keys"
            components={{ navto: <StyledLink onClick={onSelectKeys} /> }}
          />
        </div>

        <div>
          <Trans
            i18nKey="modals.faq.how.quests"
            components={{ navto: <StyledLink onClick={onSelectQuests} /> }}
          />
        </div>
      </Answer>

      <Question>{t('modals.faq.updated.title')}</Question>
      <Answer>{t('modals.faq.updated.answer')}</Answer>

      <Question>{t('modals.faq.progress.title')}</Question>
      <Answer>{t('modals.faq.progress.answer')}</Answer>

      <Question>{t('modals.faq.weighted.title')}</Question>
      <Answer>{t('modals.faq.weighted.answer')}</Answer>

      <Question>{t('modals.faq.mobile.title')}</Question>
      <Answer>{t('modals.faq.mobile.answer')}</Answer>

      <Question>{t('modals.faq.decrease.title')}</Question>
      <Answer>{t('modals.faq.decrease.answer')}</Answer>
    </Container>
  )
}

const Container = styled(Modal)`
  width: ${sc.spacing(80)};
`

const Question = styled.div`
  color: ${sc.fontColor};
`

const Answer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sc.spacing(2)};
  color: ${sc.secondary};
`

const FIR = styled.span`
  color: ${sc.fir};
`

const StyledLink = styled(Link)`
  color: ${sc.fontColor};
  display: inline-block;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`
