import { useContext } from 'react'
import { context } from './context'
import { AppContext } from './Provider'

export default () => {
  const {
    wipes: { wipes, setWipes },
  } = useContext<AppContext>(context)

  return {
    wipes,
    setWipes,
    getLatestWipe: (): string => {
      if (!wipes?.length) {
        return ''
      }

      const latest = wipes[0]
      const chunks = latest.split('.')

      if (chunks.length === 3) {
        return `${chunks[0]}.${chunks[1]}`
      }

      if (chunks.length === 2) {
        return chunks.join('.')
      }

      return latest
    },
  }
}
