import React from 'react'
import styled, { keyframes } from 'styled-components'
import { sc } from 'src/styles'

type Props = {
  size?: number
  color?: string
  className?: string
}

export default ({ size, color, className }: Props) => {
  const style = {
    width: size || 22,
    height: size || 22,
    borderBottomColor: color || sc.primary,
  }

  return <Container style={style} className={className} />
}

const SpinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const Container = styled.div`
  display: flex;
  align-self: center;

  margin: 0 auto;
  border: 2px solid transparent;
  border-radius: 50%;
  border-bottom-color: ${sc.primary};

  animation: ${SpinAnimation} 700ms linear infinite;
`
