import { Version } from 'src/types'
import v2_3_0_images from './v2.3.0'
import v2_2_4_images from './v2.2.4'
import v2_4_0_images from './v2.4.0'
import v2_5_0_images from './v2.5.0'

export default [
  {
    id: 'v2.5.5',
    date: 'Feb 10, 2024',
    changes: [
      'Added missing 0.14.0 "Ground Zero" quests',
      'Fixed an issue with "Acquaintance" quest not tracking objective items',
    ],
  },
  {
    id: 'v2.5.0',
    date: 'Nov 08, 2023',
    images: v2_5_0_images,
    changes: [
      'Added Hideout module item tracking',
      'Clicking a module name will "favorite" it, and sort it towards the top of the list',
      "All tracking data is saved to your account if logged in, or browser's local storage otherwise",
    ],
  },
  {
    id: 'v2.4.0',
    date: 'Nov 03, 2023',
    images: v2_4_0_images,
    changes: [
      'Added public Profile section to showcase your progression for each Wipe',
      'Available at /profile/{username} (i.e. <a href="https://tarkov.app/profile/rat" target="_blank" rel="noopener noreferrer">tarkov.app/profile/rat</a>)',
    ],
  },
  {
    id: 'v2.3.2',
    date: 'Oct 18, 2023',
    changes: [
      'Added change log & notification modals',
      "Notification modal will auto show up on app load whenever there's a new version release",
    ],
  },
  {
    id: 'v2.3.0',
    date: 'Oct 12, 2023',
    images: v2_3_0_images,
    changes: [
      'You can now create an account and store your tracked data permanently!',
      'With this change you no longer need to manually sync to mobile device, just login on your phone and your data will be the same',
      "If you prefer not creating an account, everything will still work exactly the same as before (saving to browser's local storage)",
    ],
  },
  {
    id: 'v2.2.4',
    date: 'Sep 08, 2023',
    images: v2_2_4_images,
    changes: ['Added ability to Import / Export your data'],
  },
  {
    id: 'v2.2.0',
    date: 'Aug 10, 2023',
    changes: [
      'Added Quests Tracker',
      "You can individually track a required item for a quest by selecing it's icon inside the Quest card",
      'You can un-do tracking a quest by selecting it from the "Completed" column',
      "Tracking a quest automatically tracks all of it's required items, and updates your list",
    ],
  },
  {
    id: 'v2.1.5',
    date: 'Aug 05, 2023',
    changes: [
      'Added progress bar to "Items" list to indicate your overall progression to Kappa',
    ],
  },
] as Version[]
