import React from 'react'
import styled from 'styled-components'
import { sc } from 'src/styles'
import { assetsDomain } from 'src/constants'

enum Size {
  Tiny = 'tiny',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

type Props = {
  id: string
  size?: `${Size}`
  className?: string
}

export default ({ id, className, size = Size.Small }: Props) => {
  if (!id) {
    return null
  }

  const spacing = {
    [Size.Tiny]: 2,
    [Size.Small]: 3,
    [Size.Medium]: 4,
    [Size.Large]: 5,
  }

  return <Container $id={id} $size={spacing[size]} className={className} />
}

const Container = styled.div<{
  $id: string
  $size: number
}>`
  width: ${props => sc.spacing(props.$size)};
  height: ${props => sc.spacing(props.$size)};
  background-image: url('${assetsDomain}/${props => props.$id}-icon.webp');
  background-size: contain;
  border: 1px solid ${sc.secondary};
  flex-shrink: 0;
`
