import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import {
  useApi,
  useWipes,
  useSession,
  useAnalytics,
  useItemData,
  useQuestData,
  useHideoutData,
  useSettings,
  useGlobal,
} from 'src/context'
import versions from 'src/constants/changelog'
import { PageTitle } from 'src/constants'
import {
  Settings,
  RustUserItem,
  RustUserQuest,
  RustUserHideoutModule,
} from 'src/types'

import Header from './Header'

const App = () => {
  const { api } = useApi()
  const { setWipes } = useWipes()
  const { setSession } = useSession()
  const { merge: mergeItems } = useItemData()
  const { merge: mergeQuests } = useQuestData()
  const { merge: mergeHideout } = useHideoutData()
  const { sync: syncSettings } = useSettings()
  const { pathname } = useLocation()
  const { trackPage } = useAnalytics()
  const { setNewsModalOpen, setAppDataLoading } = useGlobal()

  const fetchSessionAndData = async () => {
    try {
      const session = await api.request('GET', '/auth/session')

      if (session) {
        setSession(session)
        api.request('GET', '/wipes').then((wipes: string[]) => {
          wipes.sort().reverse()
          setWipes(wipes)
        })
        api
          .request('GET', '/users/items')
          .then((items: RustUserItem[]) => mergeItems(items))
        api
          .request('GET', '/users/quests')
          .then((quests: RustUserQuest[]) => mergeQuests(quests))
        api
          .request('GET', '/users/hideout')
          .then((hideout: RustUserHideoutModule[]) => mergeHideout(hideout))
        api
          .request('GET', '/users/settings')
          .then((settings: Settings) => {
            syncSettings(settings)

            if (settings.last_version !== versions[0].id) {
              setNewsModalOpen(true)

              api.request('PUT', '/users/settings', {
                body: {
                  last_version: versions[0].id,
                },
              })
            }
          })
          .catch(err => {
            if (err && err.status === 404 && err.message === 'Not found') {
              setNewsModalOpen(true)

              api.request('POST', '/users/settings', {
                body: {
                  last_version: versions[0].id,
                },
              })
            }
          })
          .finally(() => setAppDataLoading(false))
      } else {
        setAppDataLoading(false)
      }
    } catch (err) {
      console.info(err)
      setAppDataLoading(false)
    }
  }

  useEffect(() => {
    fetchSessionAndData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    trackPage(PageTitle[pathname] || pathname, pathname)
  }, [pathname, trackPage])

  return (
    <>
      <Header />
      <Outlet />
    </>
  )
}

export default App
