import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Modal } from 'src/components'
import { sc } from 'src/styles'
import { paypalUrl } from 'src/constants'
import { ReactComponent as _PayPalLogo } from './paypal.svg'

type Props = {
  isOpen: boolean
  setOpen: (state: boolean) => void
  title: string
}

export default ({ isOpen, setOpen, title }: Props) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={isOpen} setOpen={setOpen} title={title}>
      <span>{t('modals.about.info1')}</span>
      <span>{t('modals.about.info2')}</span>

      <span>
        {t('modals.about.info3')}
        <br />
        <a
          href="https://github.com/averted"
          target="_blank"
          rel="noopener noreferrer"
        >
          averted
        </a>
        <br />
        <a
          href="https://github.com/av-x"
          target="_blank"
          rel="noopener noreferrer"
        >
          av-x
        </a>
      </span>

      <span>
        {t('modals.about.comments_suggestions')}{' '}
        <a href="mailto:tarkovapp@pm.me">{t('util.email')}</a>.
      </span>

      <span>{t('modals.about.donation')}</span>

      <PayPalLink href={paypalUrl} target="_blank" rel="noopener noreferrer">
        <PayPalLogo />
      </PayPalLink>
    </Modal>
  )
}

const PayPalLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${sc.fontColor};
  border-radius: 4px;
  transition: all 100ms linear;

  &:hover {
    border-bottom: none;
    background-color: #fff;
  }
`

const PayPalLogo = styled(_PayPalLogo)`
  width: 100px;
  height: 40px;
`
