// @ts-nocheck
import Inconsolata100ttf from './Inconsolata-Light.ttf'
import Inconsolata100woff2 from './Inconsolata-Light.woff2'

import Inconsolata300ttf from './Inconsolata-Regular.ttf'
import Inconsolata300woff2 from './Inconsolata-Regular.woff2'

import Inconsolata500ttf from './Inconsolata-SemiBold.ttf'
import Inconsolata500woff2 from './Inconsolata-SemiBold.woff2'

export default () => `
  @font-face {
    font-family: 'Inconsolata';
    src: url('${Inconsolata100ttf}') format('truetype'),
         url('${Inconsolata100woff2}') format('woff2');
    font-style: normal;
    font-weight: 100;
  }

  @font-face {
    font-family: 'Inconsolata';
    src: url('${Inconsolata300ttf}') format('truetype'),
         url('${Inconsolata300woff2}') format('woff2');
    font-style: normal;
    font-weight: 300;
  }

  @font-face {
    font-family: 'Inconsolata';
    src: url('${Inconsolata500ttf}') format('truetype'),
         url('${Inconsolata500woff2}') format('woff2');
    font-style: normal;
    font-weight: 500;
  }
`
