import { useContext } from 'react'
import { context } from './context'
import { AppContext } from './Provider'

export default () => {
  const {
    analytics: { trackPage, trackEvent },
  } = useContext<AppContext>(context)

  return { trackPage, trackEvent }
}
