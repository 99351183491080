import qs from 'query-string'
import { isEmpty } from 'src/helpers/objects'
import { ApiError } from 'src/types'

type Query = Record<string, unknown>
type Headers = Record<string, string>
type TextResponse = string | undefined
type Body = { [key: string]: unknown }
type RequestTODO = { [key: string]: any }

type Options = {
  body?: string | Body | FormData
  query?: Query
  headers?: Headers
}

type ParsableError = {
  error?: string
  message?: string
}

export type ResponseError = {
  error: string
  status?: number
  message?: string
  code?: string
}

type Method =
  | 'GET'
  | 'PUT'
  | 'PATCH'
  | 'POST'
  | 'DELETE'
  | 'PATCH'
  | 'get'
  | 'put'
  | 'patch'
  | 'post'
  | 'delete'
  | 'patch'

const parseTextIntoJson = (text: TextResponse): Record<string, any> => {
  try {
    return JSON.parse(text as string)
  } catch (err) {
    return { message: text }
  }
}

export default class Api {
  request(method: Method, path: string, options: Options = {}): Promise<any> {
    const req: RequestTODO = {
      url: '/api' + path,
      method,
      body: null,
      query: {},
      headers: {},
      credentials: 'include' as RequestCredentials,
    }

    if (options.query) {
      Object.assign(
        req.query,
        Object.keys(options.query).reduce((obj, key) => {
          const value = options.query && options.query[key]

          if (!!value) {
            obj[key] = value
          }

          return obj
        }, {} as Query)
      )
    }

    if (options.body) {
      if (method === 'GET') {
        Object.assign(req.query, options.body)
      } else if (options.body.toString() === '[object FormData]') {
        req.body = options.body
      } else {
        req.body = JSON.stringify(options.body)
      }
    }

    if (options.headers) {
      req.headers = { ...options.headers }
    }

    req.headers['Accept'] = req.headers['Accept'] || 'application/json'

    if (options.body && options.body.toString() !== '[object FormData]') {
      req.headers['Content-Type'] = 'application/json'
    }

    if (!isEmpty(req.query)) {
      req.url += `?${qs.stringify(req.query, { arrayFormat: 'bracket' })}`
    }

    return new Promise((resolve, reject) =>
      fetch(req.url, req)
        .then((response: Response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.text().then((text: TextResponse) => {
              text ? resolve(parseTextIntoJson(text)) : resolve({})
            })
          } else {
            response.text().then((text: TextResponse) => {
              let body: ParsableError = {}

              if (text) {
                body = parseTextIntoJson(text)
              }

              reject({
                error: '[API] Request error',
                status: response.status,
                message: body?.error || body?.message || '',
              } as ApiError)
            })
          }
        })
        .catch(err => {
          reject({
            error: '[API] Unsuccessful fetch',
            status: 0,
            message: err,
          } as ApiError)
        })
    )
  }
}
