import React from 'react'
import styled, { css } from 'styled-components'
import { sc } from 'src/styles'
import { ReactComponent as _CheckIcon } from './check.svg'
import { ReactComponent as _CancelIcon } from './cancel.svg'

type Props = {
  active: boolean
  className?: string
}

export default ({ active, className }: Props) => {
  return (
    <Container className={className}>
      <On>
        <CheckIcon />
      </On>

      <Off>
        <CancelIcon />
      </Off>

      <Slider $active={active} />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  width: ${sc.spacing(8)};
  height: ${sc.spacing(3)};
  border: 1px solid ${sc.secondary};
  overflow: hidden;
`

const On = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${sc.success};
`

const Off = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${sc.disabled};
`

const Slider = styled.div<{
  $active: boolean
}>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  border-right: 1px solid ${sc.secondary};
  background-color: ${sc.unknown};
  transition: all 100ms linear;
  box-shadow: ${sc.shadow};

  ${props =>
    props.$active
      ? css`
          left: 50%;
          border-left: 1px solid ${sc.secondary};
          border-right: none;
        `
      : ''}
`

const CheckIcon = styled(_CheckIcon)`
  width: ${sc.spacing(2)};
  height: ${sc.spacing(2)};

  & > path {
    stroke: ${sc.fontColor};
  }

  &:hover > path {
    stroke: #fff;
  }
`

const CancelIcon = styled(_CancelIcon)`
  width: ${sc.spacing(2)};
  height: ${sc.spacing(2)};

  & > path {
    stroke: ${sc.fontColor};
  }

  &:hover > path {
    stroke: #fff;
  }
`
