import { Version } from 'src/types'

export const getActiveVersion = (
  lastVersion: string | undefined,
  versions: Version[]
): string => {
  if (!lastVersion) {
    return versions[0].id
  }

  let idx = versions.findIndex(x => x.id === lastVersion)

  if (idx === -1) {
    return versions[0].id
  }

  if (idx > 0) {
    idx -= 1
  }

  return versions[idx].id
}
