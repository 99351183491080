import React, { useState, KeyboardEvent } from 'react'
import { useSnackbar } from 'notistack'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  useApi,
  useWipes,
  useSession,
  useItemData,
  useQuestData,
  useHideoutData,
  useSettings,
  useGlobal,
} from 'src/context'
import { Modal, TextInput, Button } from 'src/components'
import { getErrorMsg } from 'src/helpers/errors'
import versions from 'src/constants/changelog'
import { sc } from 'src/styles'
import {
  Settings,
  RustUserItem,
  RustUserQuest,
  RustUserHideoutModule,
} from 'src/types'

type Props = {
  isOpen: boolean
  setOpen: (state: boolean) => void
}

enum Tabs {
  Login = 'login',
  SignUp = 'sign-up',
}

export default ({ isOpen, setOpen }: Props) => {
  const { t } = useTranslation()
  const { api } = useApi()
  const { setWipes } = useWipes()
  const { setSession } = useSession()
  const { merge: mergeItems } = useItemData()
  const { merge: mergeQuests } = useQuestData()
  const { merge: mergeHideout } = useHideoutData()
  const { sync: syncSettings } = useSettings()
  const { setNewsModalOpen } = useGlobal()
  const { enqueueSnackbar } = useSnackbar()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Login)

  const onCancel = () => setOpen(false)
  const onEnter = (fn: () => void) => (ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.code === 'Enter') {
      fn()
    }
  }

  const onChangeTab = (tab: Tabs) => () => {
    setUsername('')
    setPassword('')
    setPasswordRepeat('')
    setActiveTab(tab)
  }

  const onLogin = async () => {
    try {
      const session = await api.request('POST', '/auth', {
        body: { username, password },
      })

      if (session) {
        setSession(session)
        api.request('GET', '/wipes').then((wipes: string[]) => {
          wipes.sort().reverse()
          setWipes(wipes)
        })
        api
          .request('GET', '/users/items')
          .then((items: RustUserItem[]) => mergeItems(items))
        api
          .request('GET', '/users/quests')
          .then((quests: RustUserQuest[]) => mergeQuests(quests))
        api
          .request('GET', '/users/hideout')
          .then((hideout: RustUserHideoutModule[]) => mergeHideout(hideout))
        api
          .request('GET', '/users/settings')
          .then((settings: Settings) => {
            syncSettings(settings)

            if (settings.last_version !== versions[0].id) {
              setNewsModalOpen(true)

              api.request('PUT', '/users/settings', {
                body: {
                  last_version: versions[0].id,
                },
              })
            }
          })
          .catch(err => {
            if (err && err.status === 404 && err.message === 'Not found') {
              setNewsModalOpen(true)

              api.request('POST', '/users/settings', {
                body: {
                  last_version: versions[1].id,
                },
              })
            }
          })

        onCancel()
      }
    } catch (err) {
      console.error(err)
      enqueueSnackbar(getErrorMsg(err), { variant: 'error' })
    }
  }

  const onSignUp = async () => {
    if (password !== passwordRepeat) {
      return enqueueSnackbar(t("Passwords don't match"), { variant: 'error' })
    } else if (username.length < 3) {
      return enqueueSnackbar(t('Username too short'), { variant: 'error' })
    }

    try {
      const session = await api.request('POST', '/auth/session', {
        body: {
          username,
          password,
        },
      })

      if (session) {
        setSession(session)
        onCancel()
        enqueueSnackbar(`${session.username} - Account created`, {
          variant: 'success',
        })
      }
    } catch (err) {
      console.error(err)
      enqueueSnackbar(getErrorMsg(err), { variant: 'error' })
    }
  }

  return (
    <Modal isOpen={isOpen} setOpen={setOpen} headless>
      <Header>
        <Tab
          $active={activeTab === Tabs.Login}
          onClick={onChangeTab(Tabs.Login)}
        >
          {t('login')}
        </Tab>
        <Tab
          $active={activeTab === Tabs.SignUp}
          onClick={onChangeTab(Tabs.SignUp)}
        >
          {t('sign_up')}
        </Tab>
      </Header>

      {activeTab === Tabs.Login ? (
        <Body>
          <TextInput
            value={username}
            onChange={setUsername}
            placeholder={t('modals.auth.username')}
            autoFocus
          />

          <TextInput
            type="password"
            value={password}
            onKeyUp={onEnter(onLogin)}
            onChange={setPassword}
            placeholder={t('modals.auth.password')}
          />

          <Actions>
            <Button onClick={onLogin}>{t('login')}</Button>
            <Button onClick={onCancel}>{t('cancel')}</Button>
          </Actions>
        </Body>
      ) : (
        <Body>
          <TextInput
            value={username}
            onChange={setUsername}
            placeholder={t('modals.auth.username')}
            autoFocus
          />

          <TextInput
            type="password"
            value={password}
            onChange={setPassword}
            placeholder={t('modals.auth.password')}
          />

          <TextInput
            type="password"
            value={passwordRepeat}
            onKeyUp={onEnter(onSignUp)}
            onChange={setPasswordRepeat}
            placeholder={t('modals.auth.repeat_password')}
          />

          <Actions>
            <Button onClick={onSignUp}>{t('sign_up')}</Button>
            <Button onClick={onCancel}>{t('cancel')}</Button>
          </Actions>
        </Body>
      )}
    </Modal>
  )
}

const Header = styled.div`
  display: flex;
  border: 1px solid ${sc.secondary};
  border-bottom: none;
  background-color: ${sc.background};
`

const Tab = styled.div<{
  $active: boolean
}>`
  width: 100%;
  padding: ${sc.spacing(2)};
  text-align: center;
  cursor: pointer;
  background-color: ${props =>
    props.$active ? 'transparent' : sc.backgroundDark};

  &:not(:last-child) {
    border-right: 1px solid ${sc.secondary};
  }

  &:hover {
    background-color: transparent;
  }
`

const Body = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${sc.spacing(2)};
  border: 1px solid ${sc.secondary};
  background-color: ${sc.background};

  width: 100%;
  max-height: 75dvh;
  margin: 0;
  padding: ${sc.spacing(2)};
  overflow-y: scroll;
`

const Actions = styled.div`
  display: flex;
  gap: ${sc.spacing(2)};
`
