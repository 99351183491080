import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'src/components'
import { sc } from 'src/styles'
import { useSettings } from 'src/context'
import versions from 'src/constants/changelog'
import Version from './Version'
import { getActiveVersion } from './helpers'

type Props = {
  isOpen: boolean
  setOpen: (state: boolean) => void
  title: string
}

export default ({ isOpen, setOpen, title }: Props) => {
  const { settings } = useSettings()
  const [lastViewed, setLastViewed] = useState<string | undefined>(
    settings.last_version
  )
  const [activeVersion, setActiveVersion] = useState<string | undefined>(
    getActiveVersion(settings.last_version, versions)
  )
  const lastViewedIdx = lastViewed
    ? versions.findIndex(x => x.id === lastViewed)
    : 1

  const onSelectVersion = (version: string) => {
    const lastSelectedIdx = versions.findIndex(x => x.id === version)
    setActiveVersion(version)

    if (lastSelectedIdx < lastViewedIdx) {
      setLastViewed(version)
    }
  }

  useEffect(() => {
    if (settings.last_version) {
      setLastViewed(settings.last_version)
      setActiveVersion(getActiveVersion(settings.last_version, versions))
    }
  }, [settings.last_version])

  return (
    <Container isOpen={isOpen} setOpen={setOpen} title={title}>
      <ul>
        {versions.map((version, idx) => (
          <Version
            key={version.id}
            version={version}
            onClick={onSelectVersion}
            isNew={lastViewedIdx > idx}
            isActive={activeVersion === version.id}
          />
        ))}
      </ul>
    </Container>
  )
}

const Container = styled(Modal)`
  width: ${sc.spacing(80)};

  & > section {
    padding: 0;
  }
`
