import React from 'react'
import styled from 'styled-components'
import { QuestObjective } from 'tarkov-data'
import { sc } from 'src/styles'
import { Icon } from 'src/components'
import { getItemFromTarget } from './helpers'

type Props = {
  objective: QuestObjective
}

export default ({ objective }: Props) => {
  if (objective.type === 'mark') {
    return <Icon id="5991b51486f77447b112d44f" size="large" />
  }

  if (objective.type === 'place') {
    const origItem = getItemFromTarget(objective.target)

    if (origItem) {
      return <Icon id={origItem.id} size="large" />
    }
  }

  if (objective.type === 'playerLevel') {
    return <Container>Level</Container>
  }

  if (objective.type === 'reputation') {
    return <Container>Rep</Container>
  }

  return <Container>{objective.type}</Container>
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${sc.tertiary};
  justify-content: center;
  text-transform: uppercase;
  font-size: ${sc.fontSizeSmallest};
  flex-shrink: 0;

  color: ${sc.fontColor};
  font-weight: 500;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 10px,
    ${sc.tertiary} 10px,
    ${sc.tertiary} 11px
  );
`
