import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './translations/en.json'
import de from './translations/de.json'
import fr from './translations/fr.json'

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    resources: { en, de, fr },
    interpolation: {
      escapeValue: false,
    },
  })
