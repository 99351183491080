import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import Client from './client'
import { GlobalStyle } from './styles'

import 'normalize.css/normalize.css'
import './i18n'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <BrowserRouter>
      <Client />
    </BrowserRouter>
  </React.StrictMode>
)
