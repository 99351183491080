import { useContext } from 'react'
import tarkov from 'tarkov-data'
import { compare } from 'src/helpers/strings'
import { context } from './context'
import { AppContext } from './Provider'

export default () => {
  const {
    search: { open, search, results, setOpen, setSearch, setResults },
    analytics: { trackEvent },
  } = useContext<AppContext>(context)

  return {
    open,
    search,
    results,
    setOpen,
    setSearch,
    setResults,
    onSearch: (value: string) => {
      setSearch(value)

      if (!value) {
        setResults([])
      } else {
        setResults(
          tarkov.items.filter(
            item => compare(item.shortName, value) || compare(item.name, value)
          )
        )

        trackEvent('search', { search_term: value })
      }
    },
  }
}
