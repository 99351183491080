import React, { useState, ChangeEvent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Modal } from 'src/components'
import { addTestId } from 'src/utils/tests'
import { useItemData, useQuestData, useSettings } from 'src/context'
import { exportStorageData, importStorageData } from 'src/helpers/storage'
import { sc } from 'src/styles'

type Props = {
  isOpen: boolean
  setOpen: (state: boolean) => void
  title: string
}

type Message = {
  type: 'error' | 'success'
  title: string
}

export default ({ isOpen, setOpen, title }: Props) => {
  const { t } = useTranslation()
  const { sync: syncItems } = useItemData()
  const { sync: syncQuests } = useQuestData()
  const { sync: syncSettings } = useSettings()
  const [copied, setCopied] = useState(false)
  const [importString, setImportString] = useState('')
  const [message, setMessage] = useState<Message | undefined>(undefined)
  const exportString = exportStorageData()

  const onChange = (ev: ChangeEvent<HTMLTextAreaElement>) =>
    setImportString(ev.target.value)

  const onImport = () => {
    try {
      const { items, quests, settings } = importStorageData(importString)

      if (items) {
        syncItems(items)
      }

      if (quests) {
        syncQuests(quests)
      }

      if (settings) {
        syncSettings(settings)
      }

      setOpen(false)
    } catch (err) {
      console.error(err)
      setMessage({
        type: 'error',
        title: t((err as unknown as Error).message),
      })
    }
  }

  const onCopy = () => {
    navigator.clipboard.writeText(exportString)
    setCopied(true)
    setTimeout(() => setCopied(false), 2500)
  }

  return (
    <Modal isOpen={isOpen} setOpen={setOpen} title={title}>
      <div>
        <Heading>{t('export')}:</Heading>
        <SubHeading>{t('modals.import_export.export_info')}</SubHeading>
        <TextArea
          rows={5}
          placeholder={t('export')}
          disabled
          defaultValue={exportString}
          {...addTestId('ExportTextField')}
        />
        <Button onClick={onCopy}>{t(copied ? 'copied' : 'copy')}</Button>
      </div>

      <div>
        <Heading>{t('import')}:</Heading>
        <SubHeading>{t('modals.import_export.import_info')}</SubHeading>
        <TextArea
          rows={5}
          placeholder={t('modals.import_export.import_info')}
          value={importString}
          onChange={onChange}
          {...addTestId('ImportTextField')}
        />
        <Button onClick={onImport} disabled={!importString}>
          {t('import')}
        </Button>
      </div>

      {message && (
        <FooterMessage $type={message.type}>{message.title}</FooterMessage>
      )}
    </Modal>
  )
}

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  border: 1px solid ${sc.secondary};
  background-color: #000;
  outline: none;
  color: ${sc.tertiary};
  padding: ${sc.spacing(1)};
  margin: ${sc.spacing(1)} 0;
  font-size: ${sc.fontSizeSmaller};
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  color: #000;
  background-color: ${sc.fontColor};
  border: none;
  border-radius: 4px;
  transition: all 100ms linear;
  margin: ${sc.spacing(1)} 0;
  padding: ${sc.spacing(1)};

  &:hover:not(:disabled) {
    cursor: pointer;
    border-bottom: none;
    background-color: #fff;
  }

  &:disabled {
    opacity: 0.5;
  }
`

const Heading = styled.div`
  color: ${sc.fontColor};
`

const SubHeading = styled.div`
  color: ${sc.secondary};
  font-size: ${sc.fontSizeSmall};
  margin-top: ${sc.spacing(0.5)};
`

const FooterMessage = styled.div<{
  $type: Message['type']
}>`
  color: ${props => (props.$type === 'error' ? sc.danger : sc.success)};
  text-align: center;
`
