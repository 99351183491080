import React, { useRef, MouseEvent, TouchEvent } from 'react'
import styled, { css } from 'styled-components'
import { Icon } from 'src/components'
import { DataItem } from 'tarkov-data'
import { addTestId } from 'src/utils/tests'
import { sc } from 'src/styles'
import { isMobile } from 'src/helpers/mobile'
import { DelayedTimeoutHandler } from 'src/helpers/dom'

type Props = {
  item: DataItem
  count: number
  total: number
  onAdd: (item: DataItem) => void
  onReset: (item: DataItem) => void
  isStatic?: boolean
  className?: string
}

export default ({
  item,
  count,
  total,
  onAdd,
  onReset,
  isStatic,
  className,
}: Props) => {
  const touchTracker = new DelayedTimeoutHandler<TouchEvent>({
    callback: () => !isStatic && onReset(item),
    eventRef: useRef(null),
    timeoutRef: useRef(null),
  })

  const mouseTracker = new DelayedTimeoutHandler<MouseEvent>({
    callback: () => !isStatic && onReset(item),
    eventRef: useRef(null),
    timeoutRef: useRef(null),
  })

  const onMouseDown = (ev: MouseEvent) => {
    mouseTracker.start(ev)
  }

  const onMouseUp = (ev: MouseEvent) => {
    if (mouseTracker.active() && !isStatic) {
      mouseTracker.stop()
      onAdd(item)
    }
  }

  const onTouchMove = () => touchTracker.stop()
  const onTouchStart = (ev: TouchEvent) => touchTracker.start(ev)
  const onTouchEnd = (ev: TouchEvent) => {
    if (touchTracker.active() && !isStatic) {
      touchTracker.stop()
      onAdd(item)
    }
  }

  const isDone = count >= total
  const progress = Math.min(100, (count / total) * 100)

  return (
    <Container
      key={item.id}
      onMouseUp={isMobile ? undefined : onMouseUp}
      onMouseDown={isMobile ? undefined : onMouseDown}
      onTouchStart={isMobile ? onTouchStart : undefined}
      onTouchEnd={isMobile ? onTouchEnd : undefined}
      onTouchMove={isMobile ? onTouchMove : undefined}
      onTouchCancel={isMobile ? onTouchMove : undefined}
      $done={isDone}
      $static={isStatic}
      $progress={progress}
      className={className}
      {...addTestId('TrackableItem')}
    >
      <Body>
        <Icon id={item.id} size="large" />

        <NamePlate>
          <ShortName>{item.shortName}</ShortName>
          <Name>{item.name}</Name>
        </NamePlate>
      </Body>

      {!isDone ? (
        <Progress {...addTestId('TrackableItemProgress')}>
          <span>{count || 0}</span>
          <span>/</span>
          <span>{total.toLocaleString('en-US')}</span>
        </Progress>
      ) : null}
    </Container>
  )
}

const Container = styled.li<{
  $done: boolean
  $static?: boolean
  $progress: number
}>`
  display: flex;
  align-items: center;
  position: relative;
  padding: ${sc.spacing(1)};
  border: 1px solid ${sc.secondary};
  background-color: ${sc.background};

  opacity: ${props => (props.$done ? 0.25 : 1)};
  cursor: pointer;
  user-select: none;
  box-shadow: ${sc.shadowSmall};

  ${props =>
    !props.$static
      ? css`
          &:hover {
            box-shadow: 0 0 8px rgba(140, 140, 0, 0.5);
          }
        `
      : ''}

  &:after {
    content: '';
    position: absolute;
    top: calc(100% - ${props => props.$progress}%);
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${sc.fir};
    opacity: 0.5;
    z-index: 1;
    transition: top 150ms linear;
  }
`

const Progress = styled.div`
  position: absolute;
  top: ${sc.spacing(0.5)};
  right: ${sc.spacing(0.5)};
  display: flex;
  gap: ${sc.spacing(0.25)};
  font-size: ${sc.fontSizeSmaller};
  opacity: 0.5;
  z-index: 2;
`

const Body = styled.div`
  display: flex;
  align-items: center;
  gap: ${sc.spacing(1)};
  width: 100%;
  z-index: 2;
`

const NamePlate = styled.div`
  width: calc(100% - ${sc.spacing(5)} - ${sc.spacing(2)});
`

const ShortName = styled.div`
  opacity: 0.5;
`

const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
