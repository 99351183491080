import { RefObject, useRef, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Routes } from 'src/constants'

export const useOuterState = (refs?: RefObject<HTMLElement>[]) => {
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    const onOuterClick = (ev: MouseEvent | TouchEvent) => {
      const isOutsideClick: boolean = refs
        ? refs.reduce((acc, ref) => {
            if (!acc || !ref) {
              return false
            }

            if (!ref.current || ref.current.contains(ev.target as Node)) {
              return false
            }

            return acc
          }, true)
        : false

      if (isOutsideClick) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', onOuterClick)
    document.addEventListener('touchstart', onOuterClick)

    return () => {
      document.removeEventListener('mousedown', onOuterClick)
      document.removeEventListener('touchstart', onOuterClick)
    }
  }, [refs, setOpen])

  return { isOpen, setOpen }
}

export const useHistory = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return {
    location,
    push: (to: string) => navigate(to + window.location.search),
    isActiveRoute: (route: Routes): boolean => location.pathname === route,
  }
}

type LongPressProps = {
  onDone: () => void
  holdTime?: number
}

export const useMouseLongPress = ({ onDone, holdTime }: LongPressProps) => {
  const TICK_TIME_MS = 50
  const HOLD_TIME_MS = 500

  const [timer, setTimer] = useState<number>(0)
  const intervalRef = useRef<number | null>(null)
  const intervalState = useRef<'active' | 'complete' | null>(null)

  const onMouseUp = () => {
    if (intervalRef.current) {
      setTimer(0)
      clearInterval(intervalRef.current)
    }
  }

  const onMouseDown = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    intervalRef.current = window.setInterval(() => {
      intervalState.current = 'active'

      setTimer(state => {
        if (state < 100) {
          return (state += 100 / ((holdTime || HOLD_TIME_MS) / TICK_TIME_MS))
        }

        if (intervalState.current === 'complete') {
          if (intervalRef.current) {
            clearInterval(intervalRef.current)
          }

          setTimeout(onDone)
        }

        intervalState.current = 'complete'
        return 100
      })
    }, TICK_TIME_MS)
  }

  return { timer, onMouseDown, onMouseUp }
}
