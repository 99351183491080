import React from 'react'
import tarkovData, { DataItem, QuestObjective } from 'tarkov-data'
import styled from 'styled-components'
import { sc } from 'src/styles'
import { useItemData } from 'src/context'
import { TrackableItem } from 'src/components'
import { LocationName } from 'src/constants'
import { getQuestQuant } from 'src/helpers/data'

import ObjectiveType from './ObjectiveType'

type Props = {
  objective: QuestObjective
}

export default ({ objective }: Props) => {
  const { data, add, resetOne } = useItemData()

  const item: DataItem | undefined =
    ['collect', 'find', 'key'].includes(objective.type) &&
    typeof objective.target === 'string'
      ? tarkovData.items.find(x => x.id === objective.target)
      : undefined

  if (item) {
    return (
      <TrackableItem
        item={item}
        count={data[item.id] || 0}
        total={getQuestQuant(item)}
        onAdd={add}
        onReset={resetOne}
      />
    )
  }

  const location =
    objective.locations.length > 3 || !objective.locations?.length
      ? 'Any'
      : objective.locations.map(x => LocationName[x])

  return (
    <Container>
      <ObjectiveType objective={objective} />

      <Info>
        <Description>{objective.description}</Description>
      </Info>

      <Location>{location}</Location>
    </Container>
  )
}

const Container = styled.li`
  display: flex;
  gap: ${sc.spacing(1)};
  align-items: center;
  border: 1px solid ${sc.tertiary};
  padding: ${sc.spacing(1)};
  position: relative;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
`

const Location = styled.div`
  position: absolute;
  top: 0;
  right: ${sc.spacing(0.5)};
  opacity: 0.5;
  font-size: ${sc.fontSizeSmaller};
`

const Description = styled.span`
  font-size: ${sc.fontSizeSmall};
`
