import { useContext } from 'react'
import { Settings } from 'src/types'
import { syncSettings } from 'src/helpers/storage'
import { context } from './context'
import { AppContext } from './Provider'

export default () => {
  const {
    api,
    session: { session },
    analytics: { trackEvent },
    settings: { settings, setSettings },
  } = useContext<AppContext>(context)

  return {
    settings,
    setSettings,
    toggleSetting: (key: keyof Settings) => () => {
      const value = !settings[key]
      const updated = { ...settings, [key]: value }
      setSettings(updated)
      syncSettings(updated)
      trackEvent('toggle_setting', {
        key: key,
        value: value,
      })

      if (session && api.current) {
        api.current.request('PUT', `/users/settings`, {
          body: updated,
        })
      }
    },
    sync: (settings: Settings) => {
      setSettings(settings)
      syncSettings(settings)
    },
  }
}
