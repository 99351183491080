import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { domain } from 'src/constants'
import QR from 'qrcode'

const QRCode = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  useEffect(() => {
    if (canvasRef.current) {
      QR.toCanvas(canvasRef.current, `${domain}/${window.location.search}`)
    }
    // eslint-disable-next-line
  }, [window.location.search])

  return (
    <Container>
      <canvas ref={canvasRef} />
    </Container>
  )
}

export default QRCode

const Container = styled.div`
  text-align: center;
`
