import { Context, createContext } from 'react'

// TODO: Remove any
const context: Context<any> = createContext({
  global: {
    newsModalOpen: false,
    setNewsModalOpen: () => {},
  },
  itemData: {
    collection: {},
    setCollection: () => {},
  },
  questData: {
    collection: {},
    setCollection: () => {},
  },
  hideoutData: {
    collection: {},
    setCollection: () => {},
  },
  search: {
    open: false,
    search: '',
    results: [],
    setOpen: () => {},
    setSearch: () => {},
    setResults: () => {},
  },
  settings: {
    settings: {},
    setSettings: () => {},
  },
  analytics: {
    trackPage: () => {},
    trackEvent: () => {},
  },
})

const { Provider, Consumer } = context

export { context, Provider, Consumer }
