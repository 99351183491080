import React from 'react'
import styled from 'styled-components'
import { sc } from 'src/styles'
import { Trader } from 'tarkov-data'
import PraporImg from 'src/assets/Prapor.webp'
import TherapistImg from 'src/assets/Therapist.webp'
import SkierImg from 'src/assets/Skier.webp'
import PeacekeeperImg from 'src/assets/Peacekeeper.webp'
import MechanicImg from 'src/assets/Mechanic.webp'
import RagmanImg from 'src/assets/Ragman.webp'
import JaegerImg from 'src/assets/Jaeger.webp'
import FenceImg from 'src/assets/Fence.webp'

const TraderImage: { [key: number]: string } = {
  [Trader.Prapor]: PraporImg,
  [Trader.Therapist]: TherapistImg,
  [Trader.Skier]: SkierImg,
  [Trader.Peacekeeper]: PeacekeeperImg,
  [Trader.Mechanic]: MechanicImg,
  [Trader.Ragman]: RagmanImg,
  [Trader.Jaeger]: JaegerImg,
  [Trader.Fence]: FenceImg,
}

enum Size {
  Tiny = 'tiny',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

type Props = {
  trader: Trader
  size?: `${Size}`
  onClick?: () => void
  className?: string
}

export default ({ trader, size = Size.Large, ...rest }: Props) => {
  if (trader === undefined || !TraderImage[trader]) {
    return null
  }

  const spacing = {
    [Size.Tiny]: 2,
    [Size.Small]: 4,
    [Size.Medium]: 6,
    [Size.Large]: 8,
  }

  return (
    <Container $size={spacing[size]} $src={TraderImage[trader]} {...rest} />
  )
}

const Container = styled.div<{
  $size: number
  $src: string
}>`
  width: ${props => sc.spacing(props.$size)};
  height: ${props => sc.spacing(props.$size)};
  background-image: url('${props => props.$src}');
  background-size: contain;
  border: 1px solid #000;
  flex-shrink: 0;
`
