import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Modal } from 'src/components'
import { sc } from 'src/styles'

import QRCode from './QRCode'

type Props = {
  isOpen: boolean
  setOpen: (state: boolean) => void
  title: string
}

export default ({ isOpen, setOpen, title }: Props) => {
  const { t } = useTranslation()

  return (
    <Container isOpen={isOpen} setOpen={setOpen} title={title}>
      <QRCode />

      <span>{t('modals.sync.info')}</span>
    </Container>
  )
}

const Container = styled(Modal)`
  width: ${sc.spacing(80)};
`
