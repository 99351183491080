export const compare = (str1: string, str2: string): boolean => {
  return str1.toLowerCase().includes(str2.toLowerCase())
}

export const countChars = (str: string, c: string): number => {
  let result = 0

  for (let i = 0; i < str.length; i++) {
    const a = str[i]

    if (a === c) {
      result += 1
    }
  }

  return result
}
