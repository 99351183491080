import tarkov, { Quest, Trader } from 'tarkov-data'
import { RustUserQuest, QuestsStorageData } from 'src/types'

type QuestCollection = {
  [key: number]: Quest[]
}

export const gatherQuestsFromWipeData = (
  wipeData: RustUserQuest[] | undefined
): QuestCollection => {
  let result: QuestCollection = {}

  if (!wipeData) {
    return {}
  }

  wipeData.forEach(questData => {
    if (!result[questData.trader]) {
      result[questData.trader] = []
    }

    const rootNode = tarkov.quests[questData.trader][0]
    const quest = findNodeInTree(rootNode, questData.quest_id)

    if (quest) {
      result[questData.trader].push(quest)
    }
  })

  return result
}

export const getTotalQuests = (): number => {
  return Object.keys(tarkov.quests).reduce((acc, key) => {
    return (
      acc +
      tarkov.quests[key].reduce((agg, rootNode) => agg + rootNode.treeSize, 0)
    )
  }, 0)
}

export const findNodeInTree = (root: Quest, id: string): Quest | null => {
  if (!id) {
    return null
  }

  const traverse = (node: Quest): Quest | null => {
    if (id === node.id || id === node.shortId) {
      return node
    }

    return node.children.reduce<Quest | null>(
      (acc, child) => acc || traverse(child),
      null
    )
  }

  return traverse(root)
}

// load current quests for trader based on last completed
export const splitQuests = (
  trader: Trader,
  data: QuestsStorageData
): {
  active: Quest[]
  completed: Quest[]
} => {
  const completedIds: string[] = data[trader] || []
  const roots: Quest[] = tarkov.quests[trader] || []

  let active: Quest[] = roots
  let completed: Quest[] = []

  if (completedIds.length) {
    // for each root node, traverse tree until you find one of completed ids
    roots.forEach(rootNode => {
      completedIds.forEach(questId => {
        const node = findNodeInTree(rootNode, questId)

        if (node) {
          completed.push(node)
        }
      })
    })

    active = active
      .concat(
        completed.reduce<Quest[]>(
          (acc, quest) => acc.concat(quest.children),
          []
        )
      )
      .filter(quest => !completedIds.includes(quest.id))
  }

  active.sort((a, b) => {
    if (b.treeSize === a.treeSize) {
      return (a.require.level || 100) - (b.require.level || 100)
    }

    return b.treeSize - a.treeSize
  })

  return { active, completed }
}
