import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { sc } from 'src/styles'
import { addTestId } from 'src/utils/tests'
import { Routes, UrlKey } from 'src/constants'
import { Link } from 'src/components'
import {
  useApi,
  useSession,
  useAnalytics,
  useItemData,
  useQuestData,
  useHideoutData,
} from 'src/context'
import { useHistory } from 'src/helpers/hooks'
import {
  replaceState,
  syncItemStorageData,
  syncQuestStorageData,
} from 'src/helpers/storage'

type Props = {
  closeMenu: () => void
  isLoginModalOpen: boolean
  setLoginModalOpen: (status: boolean) => void
}

export default ({ closeMenu, isLoginModalOpen, setLoginModalOpen }: Props) => {
  const { t } = useTranslation()
  const { api } = useApi()
  const { push } = useHistory()
  const { trackEvent } = useAnalytics()
  const { set: setItems } = useItemData()
  const { set: setQuests } = useQuestData()
  const { set: setHideout } = useHideoutData()
  const { session, setSession } = useSession()

  const onNav = (to: string) => () => push(to)
  const onMenuClick = (fn: () => void) => () => {
    closeMenu()
    fn()
  }

  const onLoginModalOpen = () => {
    setLoginModalOpen(true)
    trackEvent('login_modal_open')
  }

  const onLogout = () => {
    api.request('DELETE', '/auth')
    setSession(undefined)
    replaceState([UrlKey.Items, UrlKey.Quests], '')
    syncItemStorageData({})
    syncQuestStorageData({})
    setItems({})
    setQuests({})
    setHideout({})
  }

  return (
    <Container>
      {session ? (
        <>
          <Link
            onClick={onMenuClick(
              onNav(`${Routes.Profile}/${session.username}`)
            )}
          >
            {t('profile')}
          </Link>
          <Link
            {...addTestId('AuthLogoutButton')}
            onClick={onMenuClick(onLogout)}
          >
            {t('logout')}
          </Link>
        </>
      ) : (
        <Link onClick={onMenuClick(onLoginModalOpen)}>
          {t('login_sign_up')}
        </Link>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sc.spacing(3)};
`
