import React from 'react'
import styled from 'styled-components'
import { sc } from 'src/styles'
import { TrackType } from 'src/constants'
import { useItemData, useSettings } from 'src/context'
import { filterByType, getQuestQuant } from 'src/helpers/data'
import { ProgressBar } from 'src/components'

import Search from './Search'
import Tracker from './Tracker'

type Props = {
  type: TrackType
}

const App = ({ type }: Props) => {
  const { data } = useItemData()
  const { settings } = useSettings()
  const items = filterByType(type)
  const total: number = items.reduce(
    (acc, item) => (acc += getQuestQuant(item)),
    0
  )

  const found: number = items.reduce(
    (acc, item) => (data[item.id] ? (acc += data[item.id]) : acc),
    0
  )

  return (
    <Main>
      <Search />
      <StyledProgressBar
        percent={(found / total) * 100}
        $showSearch={settings.show_search}
      />
      <Tracker type={type} />
    </Main>
  )
}

export default App

const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: ${sc.spacing(2)};
  padding: ${sc.spacing(2)};
  padding-top: ${sc.spacing(8)};
  position: relative;
  z-index: 0;
`

const StyledProgressBar = styled(ProgressBar)<{
  $showSearch?: boolean
}>`
  position: sticky;
  top: ${props => sc.navHeight + (props.$showSearch ? 45 : 0)}px;
`
