import React from 'react'
import { Quest } from 'tarkov-data'
import styled from 'styled-components'
import { sc } from 'src/styles'
import { TraderIcon } from 'src/components'

type Props = {
  quest: Quest
  onDone: () => void
}

export default ({ quest, onDone }: Props) => {
  return (
    <Container onClick={onDone}>
      <TraderIcon trader={quest.giver} size="small" />

      <Info>
        <Name>{quest.name}</Name>
        <Requirements>{quest.require.level || '1'}</Requirements>
      </Info>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  gap: ${sc.spacing(1)};
  padding: ${sc.spacing(1)};
  border: 1px solid ${sc.tertiary};
  background-color: ${sc.background};
  user-select: none;
  cursor: pointer;
  flex-shrink: 0;
  z-index: 5;

  &:hover {
    box-shadow: ${sc.shadow};
    border-color: ${sc.secondary};
    background-color: ${sc.backgroundDark};

    & span {
      opacity: 1;
    }
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sc.spacing(0.5)};
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`

const Name = styled.span`
  font-size: ${sc.fontSizeSmaller};
  text-transform: uppercase;
  opacity: 0.5;
`

const Requirements = styled.div`
  font-size: ${sc.fontSizeSmaller};
  color: ${sc.secondary};
`
