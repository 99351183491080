import { DataItem } from 'tarkov-data'
import _items from 'tarkov-data/data/items.json'

export const getItemFromTarget = (
  target: string | undefined
): DataItem | undefined => {
  if (!target) {
    return undefined
  }

  return (_items as Record<string, DataItem>)[target]
}
