import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { sc } from 'src/styles'

type Props = {
  onClick: () => void
  active?: boolean
  children?: ReactNode
  className?: string
}

export default ({
  onClick,
  active,
  children,
  className = '',
  ...rest
}: Props) => {
  return (
    <Container
      className={`ta-lnk ${className}`}
      onClick={onClick}
      $active={Boolean(active)}
      {...rest}
    >
      {children}
    </Container>
  )
}

const Container = styled.div<{
  $active: boolean
}>`
  cursor: pointer;
  color: ${sc.secondary};

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  ${props =>
    props.$active
      ? css`
          color: ${sc.fontColor};
        `
      : ''}
`
