import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { sc } from 'src/styles'
import { TextInput } from 'src/components'
import { useSearch, useSettings } from 'src/context'

const Search = () => {
  const { t } = useTranslation()
  const { settings } = useSettings()
  const { search, onSearch } = useSearch()

  if (!settings.show_search) {
    return null
  }

  return (
    <SearchInput
      withClear
      value={search}
      onChange={onSearch}
      placeholder={t('search')}
    />
  )
}

export default Search

const SearchInput = styled(TextInput)`
  position: sticky;
  top: ${sc.navHeight}px;
  box-shadow: ${sc.shadowLarge};
  z-index: 5;

  & > input {
    font-size: ${sc.fontSizeLarger};

    &::placeholder {
      font-size: ${sc.fontSizeLarge};
    }
  }
`
