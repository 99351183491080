import React, { useMemo, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { sc } from 'src/styles'
import { useParams } from 'react-router-dom'
import { useApi } from 'src/context'
import { TrackType } from 'src/constants'
import { Spinner, ProgressBar } from 'src/components'
import { RustUserItem, RustUserQuest } from 'src/types'
import { filterByType, getQuestQuant } from 'src/helpers/data'
import { getTotalQuests, gatherQuestsFromWipeData } from 'src/helpers/quests'

import ItemTracker from 'src/views/ItemTracker/Tracker'
import CompletedQuestCard from 'src/views/QuestTracker/CompletedQuestCard'

type WipeData = {
  items?: RustUserItem[]
  quests?: RustUserQuest[]
}

const Profile = () => {
  const { t } = useTranslation()
  const { api } = useApi()
  const { username } = useParams()
  const [wipes, setWipes] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedWipe, setSelectedWipe] = useState<string | undefined>(
    undefined
  )
  const [wipeData, setWipeData] = useState<WipeData>({})

  const fetchWipeData = async (wipe: string) => {
    setLoading(true)

    try {
      const items = await api.request('GET', `/users/${username}/items`, {
        query: { wipe },
      })
      const quests = await api.request('GET', `/users/${username}/quests`, {
        query: { wipe },
      })
      setWipeData({ items, quests })
      setLoading(false)
    } catch (err) {
      console.info(err)
      setLoading(false)
    }
  }

  const fetchWipes = async () => {
    try {
      const res = await api.request('GET', `/wipes`)
      res.sort().reverse()
      setWipes(res)
      setSelectedWipe(res[0])
      fetchWipeData(res[0])
    } catch (err) {
      console.info(err)
    }
  }

  useEffect(() => {
    fetchWipes()
    // eslint-disable-next-line
  }, [])

  const onSelectWipe = (wipe: string) => () => {
    fetchWipeData(wipe)
    setSelectedWipe(wipe)
  }

  const totalQuests = useMemo(() => getTotalQuests(), [])
  const totalItems: number = useMemo(
    () =>
      filterByType(TrackType.Items).reduce(
        (acc, item) => (acc += getQuestQuant(item)),
        0
      ),
    []
  )

  // Count total items without currency (RUB / DOLLARS)
  const foundItems: number =
    wipeData?.items?.reduce(
      (acc, item) => (item.count > 100 ? acc + 1 : acc + item.count),
      0
    ) || 0

  const completedQuests: number = wipeData?.quests?.length || 0
  const quests = gatherQuestsFromWipeData(wipeData?.quests)

  return (
    <Main>
      <MainHeader>
        User:
        <Username>{username}</Username>
      </MainHeader>

      <Body>
        <Side>
          <WipeList>
            {wipes.map(wipe => (
              <WipeItem
                key={wipe}
                onClick={onSelectWipe(wipe)}
                $selected={selectedWipe === wipe}
              >
                <span>Tarkov</span>
                <b>{wipe}</b>
              </WipeItem>
            ))}
          </WipeList>
        </Side>

        <Section>
          {loading ? (
            <Spinner />
          ) : !wipeData.items?.length ? (
            <Empty>{t('wipes.empty')}</Empty>
          ) : (
            <Container>
              <Header>
                {t('wipes.quests_completed')}: {wipeData?.quests?.length || 0} /{' '}
                {totalQuests}
                <StyledProgressBar
                  percent={(completedQuests / totalQuests) * 100}
                />
              </Header>

              <Padded>
                {Object.keys(quests).map(key => (
                  <CompletedQuests
                    key={`completed-quest-${key}`}
                    className="no-scroll"
                  >
                    {quests[key as unknown as number].map(quest => (
                      <CompletedQuestCard
                        key={quest.id}
                        quest={quest}
                        onDone={() => {}}
                      />
                    ))}
                  </CompletedQuests>
                ))}
              </Padded>

              <Header>
                {t('wipes.items_found')}: {foundItems} / {totalItems}
                <StyledProgressBar percent={(foundItems / totalItems) * 100} />
              </Header>

              <Padded>
                <ItemTracker
                  type={TrackType.Items}
                  wipeItems={wipeData.items}
                />
              </Padded>
            </Container>
          )}
        </Section>
      </Body>
    </Main>
  )
}

export default Profile

const Main = styled.main`
  padding: ${sc.spacing(2)};
  padding-top: ${sc.spacing(8)};
  position: relative;
  z-index: 0;
`

const MainHeader = styled.header`
  display: flex;
  align-items: center;
  gap: ${sc.spacing(1)};
  margin-bottom: ${sc.spacing(2)};
  color: ${sc.secondary};
`

const Body = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`

const Side = styled.aside`
  flex-shrink: 0;
  min-width: ${sc.spacing(20)};
  user-select: none;
  position: relative;
`

const WipeList = styled.ul`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (max-width: 900px) {
    flex-direction: row;
  }
`

const WipeItem = styled.li<{
  $selected: boolean
}>`
  display: flex;
  flex-direction: column;
  color: ${props => (props.$selected ? sc.primary : sc.secondary)};
  border: 1px solid ${sc.tertiary};
  padding: ${sc.spacing(1)} ${sc.spacing(2)};
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: none;
  }

  & > span {
    font-size: ${sc.fontSizeSmall};
    opacity: 0.5;
  }

  & > b {
    font-weight: 500;
  }

  @media (max-width: 900px) {
    &:not(:last-child) {
      border-right: none;
      border-bottom: 1px solid ${sc.tertiary};
    }
  }

  ${props =>
    props.$selected
      ? css`
          color: ${sc.primary};
          background-color: ${sc.background};

          @media (max-width: 900px) {
            border-bottom: none !important;
          }

          @media (min-width: 900px) {
            border-right: none;
          }
        `
      : css`
          background-color: ${sc.backgroundDark};
        `}
`

const Section = styled.section`
  display: flex;
  align-items: center;
  border: 1px solid ${sc.tertiary};
  background-color: ${sc.background};
  margin-left: -1px;
  width: 100%;

  @media (max-width: 900px) {
    margin-left: 0;
    margin-top: -1px;
  }
`

const Empty = styled.div`
  width: 100%;
  padding: ${sc.spacing(1)};
  text-align: center;
  opacity: 0.5;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sc.spacing(2)};
  position: relative;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sc.spacing(2)};
  padding: ${sc.spacing(2)};
  padding-bottom: 0;

  position: sticky;
  top: ${sc.navHeight}px;
  z-index: 10;

  background-color: ${sc.background};
  color: ${sc.secondary};
  font-weight: 500;
`

const StyledProgressBar = styled(ProgressBar)`
  flex-shrink: 0;
`

const Padded = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sc.spacing(3)};
  padding: 0 ${sc.spacing(2)};

  &:last-child {
    padding-bottom: ${sc.spacing(2)};
  }
`

const CompletedQuests = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${sc.spacing(0.5)};
`

// TODO: This is copied from SideMenu
const Username = styled.div`
  display: inline-block;
  padding: ${sc.spacing(0.5)} ${sc.spacing(1)};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  border: 1px solid #000;
  border-radius: 4px;

  color: #000;
  background-color: ${sc.secondary};
  cursor: pointer;
`
