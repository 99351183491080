export enum Routes {
  Root = '/',
  Track = '/track',
  Profile = '/profile',
  TrackKeys = '/track/keys',
  TrackItems = '/track/items',
  TrackQuests = '/track/quests',
  TrackHideout = '/track/hideout',
}

export const PageTitle: { [key: string]: string } = Object.freeze({
  [Routes.Root]: 'Tarkov App',
  [Routes.Track]: 'Track',
  [Routes.Profile]: 'Profile',
  [Routes.TrackKeys]: 'Track - Keys',
  [Routes.TrackItems]: 'Track - Items',
  [Routes.TrackQuests]: 'Track - Quests',
  [Routes.TrackHideout]: 'Track - Hideout',
})
