type DataTestKeys = {
  SideMenu: string
  SideMenuToggle: string
  TrackableItem: string
  TrackableItemProgress: string
  ImportTextField: string
  ExportTextField: string
  NewsModalVersionId: string
  AuthLogoutButton: string
}

export const TestKeys: DataTestKeys = Object.freeze({
  SideMenu: 'ta-sidemenu',
  SideMenuToggle: 'ta-sidemenu-toggle',
  TrackableItem: 'ta-trackable-item',
  TrackableItemProgress: 'ta-trackable-item-progress',
  ImportTextField: 'ta-import-field',
  ExportTextField: 'ta-export-field',
  NewsModalVersionId: 'news-version-id',
  AuthLogoutButton: 'auth-logout-button',
})

export const getTestId = (key: keyof DataTestKeys): string =>
  `[data-testid="${TestKeys[key]}"]`

export const addTestId = (key: keyof DataTestKeys) => ({
  'data-testid': TestKeys[key],
})
