import qs from 'query-string'
import tarkov from 'tarkov-data'
import { LocalStorage, UrlKey } from 'src/constants'
import {
  ItemsStorageData,
  QuestsStorageData,
  HideoutStorageData,
  Settings,
} from 'src/types'
import { isEmpty } from './objects'
import { findNodeInTree } from './quests'

type ImportResult = {
  items?: ItemsStorageData
  quests?: QuestsStorageData
  hideout?: HideoutStorageData
  settings?: Settings
}

export const replaceState = (keys: UrlKey[], value: string): void => {
  const { origin, pathname } = window.location
  const urlData = qs.parse(window.location.search)

  keys.forEach(key => (urlData[key] = value))

  window.history.replaceState(
    {},
    '',
    `${origin}${pathname}?${qs.stringify(urlData, { encode: false })}`
  )
}

export const exportStorageData = (): string => {
  const result: Record<string, string | null> = {}
  const itemsData = localStorage.getItem(LocalStorage.ItemsDataKey)
  const questsData = localStorage.getItem(LocalStorage.QuestsDataKey)
  const settingsData = localStorage.getItem(LocalStorage.SettingsKey)

  if (itemsData) {
    result[LocalStorage.ItemsDataKey] = itemsData
  }

  if (questsData) {
    result[LocalStorage.QuestsDataKey] = questsData
  }

  if (settingsData) {
    result[LocalStorage.SettingsKey] = settingsData
  }

  return JSON.stringify(result)
}

// TODO: Validate import string
export const importStorageData = (importString: string): ImportResult => {
  let data: Record<LocalStorage, string | undefined> = {
    [LocalStorage.ItemsDataKey]: undefined,
    [LocalStorage.QuestsDataKey]: undefined,
    [LocalStorage.HideoutDataKey]: undefined,
    [LocalStorage.SettingsKey]: undefined,
  }

  const result: ImportResult = {}

  try {
    data = JSON.parse(importString)
  } catch (err) {
    throw new Error('errors.invalid_import_string')
  }

  const items = data[LocalStorage.ItemsDataKey]
  const quests = data[LocalStorage.QuestsDataKey]
  const hideout = data[LocalStorage.HideoutDataKey]
  const settings = data[LocalStorage.SettingsKey]

  if (items) {
    localStorage.setItem(LocalStorage.ItemsDataKey, items)

    try {
      result.items = JSON.parse(items)
    } catch (err) {
      throw new Error('Invalid items import string')
    }
  }

  if (quests) {
    localStorage.setItem(LocalStorage.QuestsDataKey, quests)

    try {
      result.quests = JSON.parse(quests)
    } catch (err) {
      throw new Error('Invalid quests import string')
    }
  }

  if (hideout) {
    localStorage.setItem(LocalStorage.HideoutDataKey, hideout)

    try {
      result.hideout = JSON.parse(hideout)
    } catch (err) {
      throw new Error('Invalid hideout import string')
    }
  }

  if (settings) {
    localStorage.setItem(LocalStorage.SettingsKey, settings)

    try {
      result.settings = JSON.parse(settings)
    } catch (err) {
      throw new Error('Invalid settings import string')
    }
  }

  return result
}

/**
 * Items
 */
const parseItemDataFromURL = (location: Location): ItemsStorageData => {
  const urlData = qs.parse(location.search)
  let result: ItemsStorageData = {}

  if (typeof urlData[UrlKey.Items] !== 'string') {
    return result
  }

  urlData[UrlKey.Items].split(',').forEach(chunk => {
    const [shortId, count] = chunk.split(':')
    const item = tarkov.items.find(x => x.shortId === shortId)

    if (item) {
      result[item.id] = Number(count)
    }
  })

  return result
}

export const initItemStorageData = (location: Location): ItemsStorageData => {
  let result: ItemsStorageData = JSON.parse(
    localStorage.getItem(LocalStorage.ItemsDataKey) || '{}'
  )

  const urlData = parseItemDataFromURL(location)

  if (!isEmpty(urlData)) {
    result = urlData
  }

  return result
}

export const syncItemStorageData = (data: ItemsStorageData) => {
  localStorage.setItem(LocalStorage.ItemsDataKey, JSON.stringify(data))

  const shortData: string[] = []

  Object.entries(data).forEach(([itemId, value]) => {
    const item = tarkov.items.find(x => x.id === itemId)

    if (item?.shortId) {
      shortData.push(`${item.shortId}:${value}`)
    }
  })

  replaceState([UrlKey.Items], shortData.join(','))
}

/**
 * Quests
 */
const parseQuestDataFromURL = (location: Location): QuestsStorageData => {
  const urlData = qs.parse(location.search)
  let result: QuestsStorageData = {}

  if (!urlData[UrlKey.Quests] || typeof urlData[UrlKey.Quests] !== 'string') {
    return result
  }

  urlData[UrlKey.Quests].split(',').forEach(chunk => {
    const [traderId, questShortIdsStr] = chunk.split('!')
    const shortIdsArr = questShortIdsStr.split(':')
    const questIds: string[] = []

    shortIdsArr.forEach(shortId => {
      const rootNode = tarkov.quests[traderId][0]
      const quest = findNodeInTree(rootNode, shortId)

      if (quest) {
        questIds.push(quest.id)
      }
    })

    result[Number(traderId)] = questIds
  })

  return result
}

export const initQuestStorageData = (location: Location): QuestsStorageData => {
  let result: QuestsStorageData = JSON.parse(
    localStorage.getItem(LocalStorage.QuestsDataKey) || '{}'
  )

  const urlData = parseQuestDataFromURL(location)

  if (!isEmpty(urlData)) {
    result = urlData
  }

  return result
}

export const syncQuestStorageData = (data: QuestsStorageData) => {
  localStorage.setItem(LocalStorage.QuestsDataKey, JSON.stringify(data))

  const result: string[] = []

  Object.entries(data).forEach(([traderId, questIds]) => {
    if (questIds?.length) {
      const shortIds = questIds.map(id => {
        const rootNode = tarkov.quests[traderId][0]
        const quest = findNodeInTree(rootNode, id)
        return quest?.shortId || 'invalid'
      })

      result.push(`${traderId}!${shortIds.join(':')}`)
    }
  })

  replaceState([UrlKey.Quests], result.join(','))
}

/**
 * Hideout
 */
export const initHideoutStorageData = (): HideoutStorageData => {
  const defaultHideout = '{ "Stash": { "level": 1 } }'
  const result: HideoutStorageData = JSON.parse(
    localStorage.getItem(LocalStorage.HideoutDataKey) || '{}'
  )

  if (!result || isEmpty(result)) {
    localStorage.setItem(LocalStorage.HideoutDataKey, defaultHideout)
    return JSON.parse(defaultHideout)
  }

  return result
}

export const syncHideoutStorageData = (data: HideoutStorageData) => {
  localStorage.setItem(LocalStorage.HideoutDataKey, JSON.stringify(data))
}

/**
 * Settings
 */
export const initSettings = (): Settings => {
  const localSettings = localStorage.getItem(LocalStorage.SettingsKey)

  let defaultSettings: Settings = {
    dev: false,
    show_search: true,
    show_completed: true,
    show_collector: true,
  }

  if (localSettings) {
    defaultSettings = {
      ...defaultSettings,
      ...JSON.parse(localSettings),
    }
  }

  return defaultSettings
}

export const syncSettings = (settings: Settings) => {
  const parsed = { ...settings }

  delete parsed.dev

  localStorage.setItem(LocalStorage.SettingsKey, JSON.stringify(parsed))
}
