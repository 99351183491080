import React from 'react'
import { Quest, Trader } from 'tarkov-data'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { sc } from 'src/styles'
import { useQuestData } from 'src/context'
import { splitQuests } from 'src/helpers/quests'

import QuestCard from './QuestCard'
import CompletedQuestCard from './CompletedQuestCard'

type Props = {
  trader: Trader
}

export default ({ trader }: Props) => {
  const { t } = useTranslation()
  const { data, complete, uncomplete } = useQuestData()
  const { active, completed } = splitQuests(trader, data)
  const onCompleteQuest = (quest: Quest) => () => complete(trader, quest)
  const onUncompleteQuest = (quest: Quest) => () => uncomplete(trader, quest)

  return (
    <Container>
      {completed.length ? (
        <CompletedQuests className="no-scroll">
          {completed.map(quest => (
            <CompletedQuestCard
              key={quest.id}
              quest={quest}
              onDone={onUncompleteQuest(quest)}
            />
          ))}

          <OverlayMessage>{t('completed')}</OverlayMessage>
        </CompletedQuests>
      ) : null}

      <ActiveQuests>
        {active.map(quest => (
          <QuestCard
            key={quest.id}
            quest={quest}
            onDone={onCompleteQuest(quest)}
          />
        ))}
      </ActiveQuests>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sc.spacing(2)};
  position: relative;
  z-index: 0;
`

const CompletedQuests = styled.div`
  display: flex;
  align-items: center;
  gap: ${sc.spacing(0.5)};
  position: relative;
  border: 1px solid #000;
  box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.8);
  min-height: 50px;
  overflow-x: scroll;
  width: 100%;
`

const OverlayMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #000;
  z-index: 0;
`

const ActiveQuests = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sc.spacing(2)};
  width: 100%;
`
