// @ts-nocheck
export const initAnalytics = () => {
  const GA_ID = 'G-MX2RPMJW4R'
  const head = document.getElementsByTagName('head')[0]
  const ga = document.createElement('script')
  ga.setAttribute('async', 'true')
  ga.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`)
  head.appendChild(ga)

  window.dataLayer = window.dataLayer || []
  function gtag() {
    window.dataLayer.push(arguments)
  }
  gtag('js', new Date())
  gtag('config', GA_ID, {
    send_page_view: false,
  })
  window.gtag = gtag
}
