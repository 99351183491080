import React from 'react'
import { QuestObjective } from 'tarkov-data'
import styled from 'styled-components'
import { sc } from 'src/styles'

import Objective from './Objective'

type Props = {
  objectives: QuestObjective[]
}

export default ({ objectives }: Props) => {
  return (
    <Container>
      {objectives.map(objective => (
        <Objective key={`obj-${objective.id}`} objective={objective} />
      ))}
    </Container>
  )
}

const Container = styled.ul`
  display: grid;
  grid-gap: ${sc.spacing(1)};
  grid-template-columns: repeat(auto-fill, minmax(${sc.spacing(35)}, 1fr));
  width: 100%;
`
