import { MutableRefObject } from 'react'

type TimeoutParams<T> = {
  delay?: number
  callback: () => void
  eventRef: MutableRefObject<T | null>
  timeoutRef: MutableRefObject<number | null>
}

/**
 * Utility class to delay or cancel callback firing
 */
export class DelayedTimeoutHandler<T> {
  delayTime: number = 500
  callback?: () => void = undefined
  eventRef?: MutableRefObject<T | null> = undefined
  timeoutRef?: MutableRefObject<number | null> = undefined

  constructor(params: TimeoutParams<T>) {
    this.callback = params.callback
    this.eventRef = params.eventRef
    this.timeoutRef = params.timeoutRef

    if (params.delay) {
      this.delayTime = params.delay
    }
  }

  active(): boolean {
    return Boolean(this.eventRef?.current)
  }

  start(ev: T) {
    if (this.eventRef) {
      this.eventRef.current = ev
    }

    if (this.timeoutRef) {
      this.timeoutRef.current = window.setTimeout(() => {
        if (this.active()) {
          this.callback && this.callback()
          this.stop()
        }
      }, this.delayTime)
    }
  }

  stop() {
    if (this.eventRef) {
      this.eventRef.current = null
    }

    if (this.timeoutRef?.current) {
      clearTimeout(this.timeoutRef.current)
    }
  }
}
