export const isObject = (obj: unknown): obj is { [key: string]: unknown } =>
  obj !== null && typeof obj === 'object' && !Array.isArray(obj)

export const isEmpty = (obj: unknown): boolean => {
  if (!isObject(obj)) {
    throw new Error('Value is not an object')
  }

  for (const prop in obj) {
    if (prop in obj) {
      return false
    }
  }

  return true
}
