import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { sc } from 'src/styles'
import { useWipes, useSettings, useSession } from 'src/context'
import { Avatar, Link, LanguageSelector } from 'src/components'
import { Routes } from 'src/constants'
import { addTestId } from 'src/utils/tests'
import { useOuterState, useHistory } from 'src/helpers/hooks'
import { ReactComponent as _MenuIcon } from './menu.svg'
import { ReactComponent as _ItemsIcon } from './items.svg'
import { ReactComponent as _KeysIcon } from './keys.svg'
import { ReactComponent as _HideoutIcon } from './hideout.svg'
import { ReactComponent as _QuestsIcon } from './quests.svg'

import SideMenu from './SideMenu'

export default () => {
  const { t } = useTranslation()
  const { getLatestWipe } = useWipes()
  const { session } = useSession()
  const { settings } = useSettings()
  const { push, location, isActiveRoute } = useHistory()
  const menuRef = useRef<HTMLDivElement>(null)
  const { isOpen, setOpen } = useOuterState([menuRef])
  const onToggleSideMenu = () => setOpen(state => !state)
  const onSelectKeys = () => push(Routes.TrackKeys)
  const onSelectItems = () => push(Routes.TrackItems)
  const onSelectQuests = () => push(Routes.TrackQuests)
  const onSelectHideout = () => push(Routes.TrackHideout)
  const onProfileNav = () =>
    session && push(`${Routes.Profile}/${session.username}`)

  const renderShadow = [Routes.TrackItems, Routes.TrackKeys].includes(
    location.pathname as Routes
  )
    ? !settings.show_search
    : true

  return (
    <Container $shadow={renderShadow}>
      {settings.dev && <Dev />}

      <Links>
        <StyledLink
          onClick={onSelectItems}
          active={isActiveRoute(Routes.TrackItems)}
        >
          <RouteIcon
            $active={isActiveRoute(Routes.TrackItems)}
            as={_ItemsIcon}
          />
          <span>{t('tabs.track.items')}</span>
        </StyledLink>

        <StyledLink
          onClick={onSelectKeys}
          active={isActiveRoute(Routes.TrackKeys)}
        >
          <RouteIcon $active={isActiveRoute(Routes.TrackKeys)} as={_KeysIcon} />
          <span>{t('tabs.track.keys')}</span>
        </StyledLink>

        <StyledLink
          onClick={onSelectHideout}
          active={isActiveRoute(Routes.TrackHideout)}
        >
          <RouteIcon
            $active={isActiveRoute(Routes.TrackHideout)}
            as={_HideoutIcon}
          />
          <span>{t('tabs.track.hideout')}</span>
        </StyledLink>

        <StyledLink
          onClick={onSelectQuests}
          active={isActiveRoute(Routes.TrackQuests)}
        >
          <RouteIcon
            $active={isActiveRoute(Routes.TrackQuests)}
            as={_QuestsIcon}
          />
          <span>{t('tabs.track.quests')}</span>
        </StyledLink>
      </Links>

      <Actions>
        {session ? (
          <WipesAction onClick={onProfileNav}>
            <span>{getLatestWipe()}</span>
          </WipesAction>
        ) : null}

        <LanguageSelector />

        <Action onClick={onToggleSideMenu} {...addTestId('SideMenuToggle')}>
          {session ? <Avatar username={session.username} /> : <MenuIcon />}
        </Action>
      </Actions>

      <SideMenu ref={menuRef} isOpen={isOpen} setOpen={setOpen} />
    </Container>
  )
}

const Container = styled.header<{
  $shadow?: boolean
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${sc.navHeight}px;
  background-color: ${sc.background};

  ${props =>
    props.$shadow
      ? css`
          box-shadow: ${sc.shadowLarge};
        `
      : ''}
`

const Links = styled.div`
  display: flex;
  width: 70%;
  height: 100%;
`

const StyledLink = styled(Link)`
  padding: ${sc.spacing(2)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 490px) {
    & > span {
      display: none;
    }
  }
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${sc.spacing(2)};
  cursor: pointer;
`

const WipesAction = styled(Action)`
  color: ${sc.secondary};
  align-items: normal;
  font-size: ${sc.fontSizeSmall};
  font-weight: 400;

  & > span {
    display: block;
    padding: ${sc.spacing(1)};
    border-radius: ${sc.spacing(0.5)};
    background-color: #000;
  }

  @media (max-width: 490px) {
    font-size: ${sc.fontSizeSmallest};
  }
`

const Dev = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 4px;
  background-color: ${sc.fir};
  height: ${sc.navHeight}px;
  z-index: 15;
`

const MenuIcon = styled(_MenuIcon)`
  width: ${sc.spacing(2.5)};
  height: ${sc.spacing(2.5)};

  & > path {
    stroke: ${sc.secondary};
  }

  &:hover > path {
    stroke: #fff;
  }
`

const RouteIcon = styled.svg<{
  $active: boolean
}>`
  display: none;
  width: ${sc.spacing(2.5)};
  height: ${sc.spacing(2.5)};
  flex-shrink: 0;

  & > path {
    stroke: ${props => (props.$active ? sc.primary : sc.secondary)};
  }

  @media (max-width: 490px) {
    display: block;
  }
`
