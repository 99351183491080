import React from 'react'
import styled from 'styled-components'
import { sc } from 'src/styles'

type Props = {
  percent: number
  className?: string
}

export default ({ percent, className }: Props) => {
  return <Container $percent={percent} className={className} />
}

const Container = styled.div<{
  $percent: number
}>`
  width: 100%;
  height: ${sc.spacing(1)};
  border: 1px solid ${sc.secondary};
  background-color: ${sc.background};
  overflow: hidden;
  position: relative;
  z-index: 5;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: ${props => props.$percent}%;
    background-color: ${sc.success};
    transition: all 100ms linear;
  }
`
