import React, { useState } from 'react'
import tarkov, { Trader, TraderName } from 'tarkov-data'
import styled from 'styled-components'
import { sc } from 'src/styles'
import { TraderIcon } from 'src/components'
import { splitAtIndex } from 'src/helpers/arrays'
import { useSettings } from 'src/context'

import Tracker from './Tracker'

const QuestTracker = () => {
  const { settings } = useSettings()
  const [activeTrader, setActiveTrader] = useState<Trader>(Trader.Prapor)
  let traders: Trader[] = Object.keys(tarkov.quests).map(
    x => Number(x) as Trader
  )

  if (!settings.show_collector) {
    traders = traders.filter(x => x !== Trader.Fence)
  }

  const { active, inactive } = splitAtIndex(
    traders,
    traders.indexOf(activeTrader)
  )

  return (
    <Main>
      <Header className="no-scroll">
        <TraderGroup>
          {active.map(trader => (
            <TraderCard key={`trader-${trader}`}>
              <StyledTraderIcon
                trader={trader}
                onClick={() => setActiveTrader(trader)}
                size="medium"
              />

              {activeTrader === trader && <span>{TraderName[trader]}</span>}
            </TraderCard>
          ))}
        </TraderGroup>

        <TraderGroup>
          {inactive.map(trader => (
            <TraderCard key={`trader-${trader}`}>
              <StyledTraderIcon
                trader={trader}
                onClick={() => setActiveTrader(trader)}
                size="medium"
              />

              {activeTrader === trader && <span>{TraderName[trader]}</span>}
            </TraderCard>
          ))}
        </TraderGroup>
      </Header>

      <Tracker trader={activeTrader} />
    </Main>
  )
}

export default QuestTracker

const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: ${sc.spacing(2)};
  padding: ${sc.spacing(2)};
  padding-top: ${sc.spacing(8)};
  position: relative;
  z-index: 0;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${sc.spacing(2)};
  overflow-x: scroll;
`

const TraderGroup = styled.div`
  display: flex;
  gap: ${sc.spacing(2)};
  align-self: flex-start;
`

const StyledTraderIcon = styled(TraderIcon)`
  cursor: pointer;

  &:hover {
    border-color: ${sc.secondary};
  }
`

const TraderCard = styled.div`
  display: flex;
  gap: ${sc.spacing(2)};
  align-items: center;
`
