export enum Location {
  Any = -1,
  Factory = 0,
  Customs = 1,
  Woods = 2,
  Shoreline = 3,
  Interchange = 4,
  Lab = 5,
  Reserve = 6,
  Lighthouse = 7,
  Streets = 8,
}

export const LocationName: { [key: number]: string } = Object.freeze({
  [Location.Any]: 'Any Map',
  [Location.Factory]: 'Factory',
  [Location.Customs]: 'Customs',
  [Location.Woods]: 'Woods',
  [Location.Shoreline]: 'Shoreline',
  [Location.Interchange]: 'Interchange',
  [Location.Lab]: 'The Lab',
  [Location.Reserve]: 'Reserve',
  [Location.Lighthouse]: 'Lighthouse',
  [Location.Streets]: 'Streets of Tarkov',
})
