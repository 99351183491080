import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DataItem, HideoutModule } from 'tarkov-data'
import { sc } from 'src/styles'
import { useGlobal, useHideoutData } from 'src/context'
import { Spinner, ProgressBar, TrackableItem } from 'src/components'
import {
  getAllHideoutModules,
  isModuleUnlocked,
  isModuleCompleted,
  getMaxModuleLevel,
  getCurrModuleLevel,
} from 'src/helpers/hideout'

export default () => {
  const { t } = useTranslation()
  const { appDataLoading } = useGlobal()
  const { data, trackItem, resetItem, favModule } = useHideoutData()
  const unlockedModules = getAllHideoutModules().filter(
    module => isModuleUnlocked(module, data) && !isModuleCompleted(module, data)
  )

  unlockedModules.sort((a, b) => {
    const aData = data[a.name]
    const bData = data[b.name]

    if (aData?.favorite && !bData?.favorite) {
      return -1
    } else if (bData?.favorite && !aData?.favorite) {
      return 1
    }

    if (aData?.level < bData?.level) {
      return -1
    } else if (bData?.level < aData?.level) {
      return 1
    }

    return 0
  })

  const max: number = useMemo(getMaxModuleLevel, [])
  const curr: number = useMemo(() => getCurrModuleLevel(data), [data])
  const progress: number = (curr / max) * 100

  const getCount = (item: DataItem, module: HideoutModule): number => {
    const dataItems = data[module.name]?.items

    if (!dataItems || !dataItems[item.id]) {
      return 0
    }

    return dataItems[item.id] || 0
  }

  const isFavorite = (module: HideoutModule) => {
    return Boolean(data[module.name]?.favorite)
  }

  if (appDataLoading) {
    return (
      <Main>
        <Spinner />
      </Main>
    )
  }

  return (
    <Main>
      <StyledProgressBar percent={progress} />

      {unlockedModules.length ? (
        unlockedModules.map(module => (
          <Module key={`${module.name}-${module.level}`}>
            <ModuleHeader
              onClick={() => favModule(module)}
              $favorite={isFavorite(module)}
            >
              <ModuleLevel>{module.level}</ModuleLevel>
              <ModuleName>{module.name}</ModuleName>
            </ModuleHeader>

            <Container>
              {module.items.map(item => (
                <StyledTrackableItem
                  key={`m-${module.name}-l-${module.level}-i-${item.id}`}
                  item={item}
                  count={getCount(item, module)}
                  total={item.count || 0}
                  onAdd={item => trackItem(item, module)}
                  onReset={item => resetItem(item, module)}
                />
              ))}
            </Container>
          </Module>
        ))
      ) : (
        <Empty>{t('hideout.empty')}</Empty>
      )}
    </Main>
  )
}

const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: ${sc.spacing(7)};
  padding: ${sc.spacing(2)};
  padding-top: ${sc.spacing(8)};
  position: relative;
  z-index: 0;
`

const StyledProgressBar = styled(ProgressBar)<{
  $showSearch?: boolean
}>`
  position: sticky;
  top: ${props => sc.navHeight + (props.$showSearch ? 45 : 0)}px;
`

const Module = styled.div`
  border: 1px solid ${sc.secondary};
  padding: ${sc.spacing(1)};
  position: relative;

  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 20px,
    ${sc.tertiary} 20px,
    ${sc.tertiary} 21px
  );
  background-color: ${sc.backgroundDark};
`

const Container = styled.div`
  display: grid;
  grid-gap: ${sc.spacing(1)};
  grid-template-columns: repeat(auto-fill, minmax(${sc.spacing(35)}, 1fr));
  width: 100%;
`

const ModuleHeader = styled.div<{
  $favorite: boolean
}>`
  display: flex;
  position: absolute;
  bottom: 100%;
  left: -1px;
  color: ${props => (props.$favorite ? sc.hideoutFav : sc.secondary)};
  font-weight: 500;
  border: 1px solid ${sc.secondary};
  cursor: pointer;
  user-select: none;

  &:hover {
    color: ${sc.hideoutFav};
  }

  & > * {
    &:not(:last-child) {
      border-right: 1px solid ${sc.secondary};
    }
  }
`

const ModuleLevel = styled.div`
  padding: ${sc.spacing(0.25)} ${sc.spacing(1)};
  border-right: none;
`

const ModuleName = styled.div`
  padding: ${sc.spacing(0.25)} ${sc.spacing(1)};
  letter-spacing: 0.5px;
`

const StyledTrackableItem = styled(TrackableItem)`
  opacity: 1;

  &:after {
    background-color: ${sc.success};
  }
`

const Empty = styled.div`
  color: ${sc.secondary};
  text-align: center;
`
