import React, { useEffect, useCallback, ReactNode } from 'react'
import styled from 'styled-components'
import { sc } from 'src/styles'

type Props = {
  isOpen: boolean
  setOpen: (state: boolean) => void
  title?: string
  children?: ReactNode
  className?: string
  headless?: boolean
}

export default ({
  isOpen,
  setOpen,
  title,
  className,
  children,
  headless,
}: Props) => {
  const onClose = useCallback(() => setOpen(false), [setOpen])

  useEffect(() => {
    const onKeydown = (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        onClose()
      }
    }

    document.addEventListener('keydown', onKeydown)

    return () => {
      document.removeEventListener('keydown', onKeydown)
    }
  }, [onClose])

  return (
    <Container $isOpen={isOpen}>
      {headless ? (
        <HeadlessMain className={className}>{children}</HeadlessMain>
      ) : (
        <Main className={className}>
          <Heading>
            <h3>{title}</h3>
            <Close onClick={onClose}>&#215;</Close>
          </Heading>

          <Body>{children}</Body>
        </Main>
      )}

      <Backdrop onClick={onClose} />
    </Container>
  )
}

const Container = styled.div<{
  $isOpen: boolean
}>`
  display: ${props => (props.$isOpen ? 'block' : 'none')};
  position: relative;
  z-index: 35;
`

const Main = styled.main`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${sc.spacing(70)};

  color: ${sc.secondary};
  border: 1px solid ${sc.secondary};
  background-color: ${sc.background};
  z-index: 35;

  @media (max-width: 900px) {
    width: 100% !important;
  }
`

const HeadlessMain = styled(Main)`
  color: inherit;
  border: none;
  background-color: transparent;
  z-index: 35;
`

const Heading = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${sc.spacing(5)};
  color: ${sc.fontColor};

  padding: 0 ${sc.spacing(2)};
  border-bottom: 1px solid ${sc.secondary};

  & > h3 {
    font-size: ${sc.fontSize};
    font-weight: 500;
    letter-spacing: 0.6px;
    text-transform: capitalize;
  }
`

const Close = styled.div`
  cursor: pointer;
  color: ${sc.secondary};
  font-size: ${sc.fontSizeLargest};

  &:hover {
    color: ${sc.fontColor};
  }
`

const Body = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${sc.spacing(2)};

  width: 100%;
  max-height: 75dvh;
  margin: 0;
  padding: ${sc.spacing(2)};
  overflow-y: scroll;
`

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  background-color: #000;
  z-index: 25;
`
