import React from 'react'
import styled from 'styled-components'
import { sc } from 'src/styles'
import { Version } from 'src/types'
import { addTestId } from 'src/utils/tests'

type Props = {
  version: Version
  isNew: boolean
  isActive: boolean
  onClick: (version: string) => void
}

export default ({ version, isActive, isNew, onClick }: Props) => {
  return (
    <Container>
      <Header onClick={() => onClick(version.id)}>
        <VersionId
          $active={isActive}
          $new={isNew}
          {...addTestId('NewsModalVersionId')}
        >
          {version.id}
        </VersionId>
        <VersionDate>{version.date}</VersionDate>
      </Header>

      {isActive ? (
        <Changes>
          {version.changes.map((change, idx) => (
            <Log key={`${version.id}-${idx}`}>
              <span>-</span>
              <span dangerouslySetInnerHTML={{ __html: change }} />
            </Log>
          ))}

          {version.images?.length ? (
            <LogImages>
              {version.images.map((imageUrl, idx) => (
                <Image
                  key={`version-${version.id}-img-${idx}`}
                  $src={imageUrl}
                />
              ))}
            </LogImages>
          ) : null}
        </Changes>
      ) : null}
    </Container>
  )
}

const Container = styled.li`
  &:not(:last-child) {
    border-bottom: 1px solid ${sc.secondary};
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${sc.spacing(2)};
  cursor: pointer;
`

const VersionId = styled.span<{
  $new: boolean
  $active: boolean
}>`
  display: flex;
  align-items: center;
  color: ${props => (props.$active ? sc.primary : 'inherit')};

  ${props =>
    props.$new
      ? `
    &:after {
      content: '';
      width: ${sc.spacing(0.75)};
      height: ${sc.spacing(0.75)};
      background-color: red;
      border-radius: 50%;
      margin-left: ${sc.spacing(1.5)};
    }
  `
      : ''}
`

const VersionDate = styled.span`
  color: ${sc.primary};
  opacity: 0.5;
`

const Changes = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sc.spacing(1)};
  padding: ${sc.spacing(2)};
  background-color: ${sc.backgroundDark};
  border-top: 1px solid ${sc.secondary};
`

const Log = styled.div`
  display: flex;
  gap: ${sc.spacing(1)};
`

const LogImages = styled.div`
  display: flex;
  gap: ${sc.spacing(1)};
  margin-left: ${sc.spacing(2)};
`

const Image = styled.div<{
  $src: string
}>`
  width: 90px;
  height: 70px;
  background-image: url('${props => props.$src}');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
  border: 1px solid #000;
  flex-shrink: 0;

  &:hover {
    width: 200px;
    height: 170px;
  }
`
