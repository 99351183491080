import tarkovData, { Quest, Trader, DataItem } from 'tarkov-data'
import { useContext } from 'react'
import { syncQuestStorageData } from 'src/helpers/storage'
import { QuestsStorageData, RustUserQuest } from 'src/types'
import { context } from './context'
import useItemData from './useItemData'
import { AppContext } from './Provider'

export default () => {
  const {
    api,
    session: { session },
    questData: { collection, setCollection },
    analytics: { trackEvent },
  } = useContext<AppContext>(context)
  const { updateAll } = useItemData()

  return {
    data: collection,

    set: (data: QuestsStorageData) => {
      setCollection(data)
    },

    complete: (trader: Trader, quest: Quest) => {
      const updated = { ...collection }

      if (!updated[trader]) {
        updated[trader] = []
      }

      const idx = updated[trader].indexOf(quest.id)

      if (idx === -1) {
        updated[trader].push(quest.id)

        setCollection(updated)
        syncQuestStorageData(updated)

        // track all quest objective items
        const trackableItems = quest.objectives.reduce<[string, number][]>(
          (acc, obj) => {
            const item: DataItem | undefined =
              ['collect', 'find', 'key'].includes(obj.type) && obj.target
                ? tarkovData.items.find(x => x.id === obj.target)
                : undefined

            if (item) {
              acc.push([item.id, obj.count || 0])
            }

            return acc
          },
          []
        )

        updateAll(trackableItems)

        trackEvent('complete_quest', {
          quest_id: quest.id,
          quest_name: quest.name,
        })

        if (session && api.current) {
          api.current.request('POST', `/users/quests`, {
            body: {
              quest_id: quest.id,
              trader: quest.giver,
            },
          })
        }
      }
    },

    uncomplete: (trader: Trader, quest: Quest) => {
      const updated = { ...collection }

      if (!updated[trader]) {
        updated[trader] = []
      }

      const idx = updated[trader].indexOf(quest.id)

      if (idx > -1) {
        updated[trader].splice(idx, 1)

        setCollection(updated)
        syncQuestStorageData(updated)

        trackEvent('uncomplete_quest', {
          quest_id: quest.id,
          quest_name: quest.name,
        })

        if (session && api.current) {
          api.current.request('DELETE', `/users/quests/${quest.id}`)
        }
      }
    },

    reset: () => {
      setCollection({})
      syncQuestStorageData({})
      trackEvent('reset_all_quests')

      if (session && api.current) {
        api.current.request('DELETE', `/users/quests`)
      }
    },

    sync: (quests: QuestsStorageData) => {
      setCollection(quests)
      syncQuestStorageData(quests)
    },

    merge: (data: RustUserQuest[]) => {
      const updated: QuestsStorageData = { ...collection }
      const parsed = data.reduce<QuestsStorageData>((acc, quest) => {
        if (!acc[quest.trader]) {
          acc[quest.trader] = []
        }

        acc[quest.trader].push(quest.quest_id)
        return acc
      }, {})

      Object.keys(parsed).forEach(trader => {
        if (parsed[trader]) {
          if (
            !updated[trader] ||
            updated[trader].length < parsed[trader].length
          ) {
            updated[trader] = parsed[trader]
          }
        }
      })

      setCollection(updated)
    },
  }
}
