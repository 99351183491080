export const domain = 'https://tarkov.app'
export const assetsDomain = 'https://assets.tarkov.dev'
export const githubUrl = 'https://github.com/averted/tarkov'
export const paypalUrl =
  'https://www.paypal.com/donate/?business=8J56KHWPQ3HCL&no_recurring=0&item_name=Thanks+for+contributing+to+Tarkov+App+development%21&currency_code=USD'

type TransLanguage = {
  nativeName: string
}

export const langs: Record<string, TransLanguage> = {
  en: { nativeName: 'English' },
  de: { nativeName: 'Deutsch' },
  fr: { nativeName: 'Français' },
  // ru: { nativeName: 'Русский' },
  // ua: { nativeName: 'Українська' },
}

export * from './tracker'
export * from './storage'
export * from './routes'
export * from './quests'
