import { DataItem } from 'tarkov-data'
import { useContext } from 'react'
import { context } from './context'
import { AppContext } from './Provider'
import { getQuestQuant } from 'src/helpers/data'
import { syncItemStorageData } from 'src/helpers/storage'
import { ItemsStorageData, RustUserItem } from 'src/types'

export default () => {
  const {
    api,
    session: { session },
    itemData: { collection, setCollection },
    analytics: { trackEvent },
  } = useContext<AppContext>(context)

  return {
    data: collection,

    set: (data: ItemsStorageData) => {
      setCollection(data)
    },

    add: (item: DataItem) => {
      const updated = { ...collection }

      if (!updated[item.id]) {
        updated[item.id] = 0
      }

      if (updated[item.id] < getQuestQuant(item)) {
        updated[item.id] += 1

        setCollection(updated)
        syncItemStorageData(updated)
        trackEvent('select_item', {
          item_id: item.id,
          item_name: item.name,
        })

        if (session && api.current) {
          api.current.request('POST', `/users/items/${item.id}/add`)
        }
      }
    },

    update: (key: string, value: number | '') => {
      const updated = { ...collection }

      if (!value) {
        delete updated[key]
      } else {
        updated[key] = value
      }

      setCollection(updated)
      syncItemStorageData(updated)

      if (session && api.current) {
        api.current.request('POST', `/users/items`, {
          body: {
            item_id: key,
            count: value || 0,
          },
        })
      }
    },

    updateAll: (itemCounts: [string, number][]) => {
      const updated = { ...collection }

      itemCounts.forEach(([id, count]) => {
        if (!count) {
          delete updated[id]
        } else {
          updated[id] = count
        }

        if (session && api.current) {
          api.current.request('POST', `/users/items`, {
            body: {
              item_id: id,
              count: count || 0,
            },
          })
        }
      })

      setCollection(updated)
      syncItemStorageData(updated)
    },

    resetOne: (item: DataItem) => {
      const updated = { ...collection }

      if (updated[item.id]) {
        delete updated[item.id]
      }

      setCollection(updated)
      syncItemStorageData(updated)
      trackEvent('reset_one', {
        item_id: item.id,
        item_name: item.name,
      })

      if (session && api.current) {
        api.current.request('DELETE', `/users/items/${item.id}`)
      }
    },

    reset: () => {
      setCollection({})
      syncItemStorageData({})
      trackEvent('reset_all')

      if (session && api.current) {
        api.current.request('DELETE', `/users/items`)
      }
    },

    sync: (items: ItemsStorageData) => {
      setCollection(items)
      syncItemStorageData(items)
    },

    merge: (data: RustUserItem[]) => {
      const updated: ItemsStorageData = { ...collection }
      const parsed = data.reduce<ItemsStorageData>((acc, item) => {
        acc[item.item_id] = item.count
        return acc
      }, {})

      Object.keys(parsed).forEach(key => {
        if (!updated[key] || parsed[key] > updated[key]) {
          updated[key] = parsed[key]
        }
      })

      setCollection(updated)
    },
  }
}
