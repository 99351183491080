const BASE = 8

export default {
  primary: '#eae0d5',
  secondary: '#c6ac8f',
  tertiary: '#84705b',
  unknown: '#000',

  // colors
  background: '#22333b',
  backgroundDark: '#19252b',
  success: '#009a00',
  successBright: '#00cd00',
  warning: '#e6bf00',
  danger: '#ff2e00',
  disabled: '#797979',
  paypal: '#2872d9',

  // items
  fir: '#ff2e00',

  // quests
  quest: '#46acc2',

  // hideout
  hideout: '#a6a6a6',
  hideoutFav: '#DE6B48',

  // fonts
  fontColor: '#eae0d5',
  fontFamily: "'Inconsolata', monospace",
  fontContrast: '#000',

  fontSizeSmallest: '10px',
  fontSizeSmaller: '12px',
  fontSizeSmall: '14px',
  fontSize: '16px',
  fontSizeLarge: '20px',
  fontSizeLarger: '24px',
  fontSizeLargest: '28px',

  // shadows
  shadowSmaller: '0 0 5px rgba(10,10,10,0.8)',
  shadowSmall: '0 0 10px rgba(10,10,10,0.8)',
  shadow: '0 0 20px rgba(10,10,10,0.8)',
  shadowLarge: '0 0 30px rgba(10,10,10,0.8)',
  shadowLarger: '0 0 40px rgba(10,10,10,0.8)',
  shadowCustom: (size: number) => `0 0 ${size}px rgba(10,10,10,0.8)`,

  // spacing
  spacing: (size: number) => `${BASE * size}px`,

  // nav
  navHeight: 50,
}
