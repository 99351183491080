import React, { useState, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { addTestId } from 'src/utils/tests'
import { Link, Toggle, ConfirmModal } from 'src/components'
import {
  useItemData,
  useQuestData,
  useHideoutData,
  useAnalytics,
  useSettings,
  useSession,
  useGlobal,
} from 'src/context'
import { sc } from 'src/styles'

import NewsModal from './NewsModal'
import FAQsModal from './FAQsModal'
import SyncModal from './SyncModal'
import AboutModal from './AboutModal'
import LoginModal from './LoginModal'
import AuthSection from './AuthSection'
import ImportExportModal from './ImportExportModal'

type Props = {
  isOpen: boolean
  setOpen: (state: boolean) => void
}

export default forwardRef<HTMLDivElement, Props>(
  ({ isOpen, setOpen }: Props, ref) => {
    const { t } = useTranslation()
    const { session } = useSession()
    const { reset: resetItems } = useItemData()
    const { reset: resetQuests } = useQuestData()
    const { reset: resetHideout } = useHideoutData()
    const { trackEvent } = useAnalytics()
    const { settings, toggleSetting } = useSettings()
    const { newsModalOpen, setNewsModalOpen } = useGlobal()
    const [isFAQsModalOpen, setFAQsModalOpen] = useState(false)
    const [isSyncModalOpen, setSyncModalOpen] = useState(false)
    const [isAboutModalOpen, setAboutModalOpen] = useState(false)
    const [isLoginModalOpen, setLoginModalOpen] = useState(false)
    const [isImportExportModalOpen, setImportExportModalOpen] = useState(false)
    const [isResetConfirmModalOpen, setResetConfirmModalOpen] = useState(false)

    const onClose = () => setOpen(false)
    const onReset = () => {
      resetItems()
      resetQuests()
      resetHideout()
      setResetConfirmModalOpen(false)
    }

    const onOpenNewsModal = () => {
      setNewsModalOpen(true)
      trackEvent('change_log_modal_open')
    }

    const onOpenAboutModal = () => {
      setAboutModalOpen(true)
      trackEvent('about_modal_open')
    }

    const onOpenSyncModal = () => {
      setSyncModalOpen(true)
      trackEvent('sync_modal_open')
    }

    const onOpenFAQsModal = () => {
      setFAQsModalOpen(true)
      trackEvent('faqs_modal_open')
    }

    const onOpenImportExportModal = () => {
      setImportExportModalOpen(true)
      trackEvent('import_export_modal_open')
    }

    const onOpenResetConfirmModal = () => setResetConfirmModalOpen(true)
    const onCloseResetConfirmModal = () => setResetConfirmModalOpen(false)

    const onMenuClick = (fn: () => void) => () => {
      fn()
      onClose()
    }

    return (
      <Container ref={ref} $open={isOpen} {...addTestId('SideMenu')}>
        <Heading>
          <Version>v2.5.7</Version>

          {session ? (
            <Username onClick={onClose}>{session.username}</Username>
          ) : (
            <CloseIcon onClick={onClose}>&#215;</CloseIcon>
          )}
        </Heading>

        <Section>
          <AuthSection
            closeMenu={onClose}
            setLoginModalOpen={setLoginModalOpen}
            isLoginModalOpen={isLoginModalOpen}
          />
        </Section>

        <Section>
          <Link onClick={onMenuClick(onOpenAboutModal)}>{t('about')}</Link>
          <Link onClick={onMenuClick(onOpenSyncModal)}>{t('sync')}</Link>
          <Link onClick={onMenuClick(onOpenFAQsModal)}>
            {t('instructions')} / FAQs
          </Link>
          <Link onClick={onMenuClick(onOpenImportExportModal)}>
            {t('import')} / {t('export')}
          </Link>
          <Link onClick={onMenuClick(onOpenNewsModal)}>{t('Change Log')}</Link>
          <ResetLink onClick={onMenuClick(onOpenResetConfirmModal)}>
            {t('reset')}
          </ResetLink>
        </Section>

        <Section>
          <SettingsRow onClick={toggleSetting('show_search')}>
            <Toggle active={Boolean(settings.show_search)} />
            <div>{t('settings.show_search')}</div>
          </SettingsRow>

          <SettingsRow onClick={toggleSetting('show_completed')}>
            <Toggle active={Boolean(settings.show_completed)} />
            <div>{t('settings.show_completed')}</div>
          </SettingsRow>

          <SettingsRow onClick={toggleSetting('show_collector')}>
            <Toggle active={Boolean(settings.show_collector)} />
            <div>{t('settings.show_collector')}</div>
          </SettingsRow>
        </Section>

        <AboutModal
          isOpen={isAboutModalOpen}
          setOpen={setAboutModalOpen}
          title={t('about')}
        />

        <SyncModal
          isOpen={isSyncModalOpen}
          setOpen={setSyncModalOpen}
          title={t('sync')}
        />

        <FAQsModal
          isOpen={isFAQsModalOpen}
          setOpen={setFAQsModalOpen}
          title={t('faq')}
        />

        <NewsModal
          isOpen={newsModalOpen}
          setOpen={setNewsModalOpen}
          title={t('whats_new')}
        />

        {isLoginModalOpen ? (
          <LoginModal isOpen setOpen={setLoginModalOpen} />
        ) : null}

        {isImportExportModalOpen ? (
          <ImportExportModal
            isOpen
            setOpen={setImportExportModalOpen}
            title={`${t('import')} / ${t('export')}`}
          />
        ) : null}

        {isResetConfirmModalOpen ? (
          <ConfirmModal
            isOpen
            setOpen={setResetConfirmModalOpen}
            title={t('danger')}
            body={
              <>
                <span>{t('modals.reset.reset_progress')}</span>
                <span>{t('modals.reset.are_you_sure')}</span>
              </>
            }
            onAccept={onReset}
            onCancel={onCloseResetConfirmModal}
          />
        ) : null}
      </Container>
    )
  }
)

const SIDE_MENU_WIDTH = 300
const SIDE_MENU_SHADOW = 40
const Container = styled.div<{
  $open: boolean
}>`
  position: fixed;
  width: ${SIDE_MENU_WIDTH}px;
  top: 0;
  right: ${props =>
    props.$open ? 0 : `-${SIDE_MENU_WIDTH + SIDE_MENU_SHADOW}px`};
  bottom: 0;
  padding: 0 ${sc.spacing(2)};
  transition: right 120ms linear;
  background-color: ${sc.background};
  box-shadow: ${sc.shadowCustom(SIDE_MENU_SHADOW)};
  user-select: none;
  z-index: 15;

  @media (max-width: 900px) {
    width: 100%;
    right: ${props => (props.$open ? 0 : `-120%`)};
  }
`

const Version = styled.div`
  padding: ${sc.spacing(2)} 0;
`

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${sc.spacing(3)};
  padding: ${sc.spacing(3)} 0;
  border-bottom: 1px solid ${sc.secondary};
  text-align: right;

  &:last-of-type {
    border-bottom: none;
  }
`

const SettingsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;
  color: ${sc.secondary};

  &:hover {
    color: ${sc.fontColor};
  }
`

const ResetLink = styled(Link)`
  &:hover {
    color: ${sc.fir};
  }
`

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${sc.secondary};
  gap: ${sc.spacing(2)};
`

const Username = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${sc.spacing(0.5)} ${sc.spacing(1)};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;

  border: 1px solid #000;
  border-radius: 4px;

  color: #000;
  background-color: ${sc.secondary};
  cursor: pointer;
`

const CloseIcon = styled.div`
  padding: ${sc.spacing(1)};
  color: ${sc.secondary};
  font-size: ${sc.fontSizeLargest};
  cursor: pointer;

  &:hover {
    color: ${sc.fontColor};
  }
`
