type Parsable = {
  message: string
}

const isParsable = (error: unknown): error is Parsable =>
  typeof error === 'object' &&
  error !== null &&
  'message' in error &&
  typeof (error as Record<string, unknown>).message === 'string'

const toParsable = (error: unknown): Parsable => {
  if (isParsable(error)) {
    return error
  }

  if (typeof error === 'string') {
    return new Error(error)
  }

  return new Error('[001] Something went wrong')
}

export const getErrorMsg = (error: unknown): string => toParsable(error).message
