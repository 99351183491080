import React, { MouseEvent } from 'react'
import tarkov, { Quest } from 'tarkov-data'
import styled from 'styled-components'
import { sc } from 'src/styles'
import { Icon, TraderIcon } from 'src/components'
import { useItemData } from 'src/context'
import { ReactComponent as _CheckIcon } from 'src/components/Toggle/check.svg'

import Objectives from './Objectives'

type Props = {
  quest: Quest
  onDone: () => void
}

const QuestCard = ({ quest, onDone }: Props) => {
  const { data, add } = useItemData()
  const stopPropagation = (ev: MouseEvent) => ev.stopPropagation()

  const onTrackItem = (ev: MouseEvent, itemId: string) => {
    ev.stopPropagation()
    const item = tarkov.items.find(x => x.id === itemId)
    item && add(item)
  }

  const isItemComplete = (itemId: string, idx: number): boolean => {
    const count = data[itemId]

    if (typeof count !== 'undefined') {
      return idx < count
    }

    return false
  }

  const reqItemStacks: string[][] = []

  quest.objectives.forEach(ob => {
    if (['key', 'find', 'collect'].includes(ob.type) && ob.count) {
      let arr: string[] = []

      arr = new Array(ob.count > 40 ? 1 : ob.count).fill(ob.target)
      reqItemStacks.push(arr)
    }
  })

  return (
    <Container>
      <Card onClick={onDone}>
        <TraderIcon trader={quest.giver} size="large" />

        <Info>
          <Name
            onClick={stopPropagation}
            href={quest.wiki}
            target="_blank"
            rel="noopener noreferrer"
          >
            {quest.name}
          </Name>

          <Items>
            {reqItemStacks.map(stack =>
              stack.map((itemId, idx) => (
                <li
                  key={`q-${quest.id}-fir-${itemId}-${idx}`}
                  onClick={ev => onTrackItem(ev, itemId)}
                >
                  <ItemIcon
                    id={itemId}
                    size="small"
                    $complete={isItemComplete(itemId, idx)}
                  />
                  {isItemComplete(itemId, idx) && <CheckIcon />}
                </li>
              ))
            )}
          </Items>
        </Info>

        <SideContainer>
          <Requirements>Lv. {quest.require.level || '1'}</Requirements>
          {quest.treeSize ? <Unlocks>Unlocks {quest.treeSize}</Unlocks> : null}
        </SideContainer>
      </Card>

      <Objectives objectives={quest.objectives} />
    </Container>
  )
}

export default QuestCard

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sc.spacing(1)};
  border: 1px solid ${sc.secondary};
  padding: ${sc.spacing(1)};
  box-shadow: ${sc.shadow};
`

const Card = styled.div`
  position: relative;
  display: flex;
  gap: ${sc.spacing(1)};
  padding: ${sc.spacing(1)};
  border: 1px solid ${sc.secondary};
  user-select: none;
  cursor: pointer;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sc.spacing(1)};
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`

const Name = styled.a`
  text-transform: uppercase;
`

const Items = styled.ul`
  display: flex;
  align-items: center;
  gap: ${sc.spacing(1)};
  flex-wrap: wrap;

  & > li {
    position: relative;
  }
`

const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  flex-shrink: 0;
`

const Requirements = styled.div`
  font-size: ${sc.fontSizeSmall};
  color: ${sc.secondary};
`

const Unlocks = styled.div`
  font-size: ${sc.fontSizeSmall};
  opacity: 0.5;
`

const ItemIcon = styled(Icon)<{
  $complete: boolean
}>`
  border: 1px solid ${props => (props.$complete ? sc.success : sc.tertiary)};
`

const CheckIcon = styled(_CheckIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.33);

  width: ${sc.spacing(2.5)};
  height: ${sc.spacing(2.5)};

  & > path {
    stroke: ${sc.success};
  }
`
