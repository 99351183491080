import React from 'react'
import styled from 'styled-components'
import { useItemData, useSearch, useSettings } from 'src/context'
import { sc } from 'src/styles'
import { TrackType } from 'src/constants'
import {
  sortByWeight,
  filterByType,
  filterByName,
  filterOutCompleted,
  filterOutCollector,
  getQuestQuant,
} from 'src/helpers/data'
import { RustUserItem } from 'src/types'
import { TrackableItem } from 'src/components'
import Bag from './bag.png'

type Props = {
  type: TrackType
  wipeItems?: RustUserItem[]
}

export default ({ type, wipeItems }: Props) => {
  const { data, add, resetOne } = useItemData()
  const { search } = useSearch()
  const { settings } = useSettings()
  const tarkovItems = filterByType(type)
  const isStatic = Boolean(wipeItems)

  let filtered = sortByWeight(filterByName(tarkovItems, search))

  if (!settings.show_completed) {
    filtered = filterOutCompleted(filtered, data)
  }

  if (!settings.show_collector) {
    filtered = filterOutCollector(filtered, data)
  }

  if (!filtered.length) {
    return (
      <Empty>
        <img src={Bag} alt="scav.jpg" />
      </Empty>
    )
  }

  return (
    <Container>
      {filtered.map(item => {
        const wipeItem = wipeItems?.find(x => x.item_id === item.id)

        return (
          <TrackableItem
            key={item?.id || '-'}
            item={item}
            count={isStatic ? wipeItem?.count || 0 : data[item.id] || 0}
            total={getQuestQuant(item)}
            onAdd={add}
            onReset={resetOne}
            isStatic={isStatic}
          />
        )
      })}
    </Container>
  )
}

const Container = styled.ul`
  display: grid;
  grid-gap: ${sc.spacing(2)};
  grid-template-columns: repeat(auto-fill, minmax(${sc.spacing(35)}, 1fr));
  width: 100%;
`

const Empty = styled.div`
  display: flex;
  justify-content: center;

  & > img {
    width: 280px;
    margin-top: 5%;
  }
`
