import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { sc } from 'src/styles'

type Props = {
  size?: string
  onClick: () => void
  children: ReactNode
  className?: string
}

export default ({ size, children, onClick, className }: Props) => {
  return (
    <Container className={className} onClick={onClick} $size={size}>
      {children}
    </Container>
  )
}

const Container = styled.button<{
  $size?: string
}>`
  width: 100%;
  border: none;
  color: #000;
  border-radius: 3px;
  background-color: ${sc.secondary};
  padding: ${sc.spacing(1)};
  cursor: pointer;

  &:hover {
    background-color: ${sc.primary};
  }
`
