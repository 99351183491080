import React, {
  Dispatch,
  SetStateAction,
  ReactNode,
  MutableRefObject,
} from 'react'
import { DataItem } from 'tarkov-data'
import {
  Session,
  ItemsStorageData,
  QuestsStorageData,
  HideoutStorageData,
  Settings,
} from 'src/types'
import { Provider } from './context'
import Api from 'src/utils/api'

type GlobalContext = {
  appDataLoading: boolean
  setAppDataLoading: (state: boolean) => void
  newsModalOpen: boolean
  setNewsModalOpen: (state: boolean) => void
}

type SessionContext = {
  session: Session | undefined
  setSession: (value: Session | undefined) => void
}

type ItemDataContext = {
  collection: ItemsStorageData
  setCollection: Dispatch<SetStateAction<ItemsStorageData>>
}

type QuestDataContext = {
  collection: QuestsStorageData
  setCollection: Dispatch<SetStateAction<QuestsStorageData>>
}

type HideoutDataContext = {
  collection: HideoutStorageData
  setCollection: Dispatch<SetStateAction<HideoutStorageData>>
}

type SearchContext = {
  open: boolean
  search: string
  results: DataItem[]
  setOpen: Dispatch<SetStateAction<boolean>>
  setSearch: Dispatch<SetStateAction<string>>
  setResults: Dispatch<SetStateAction<DataItem[]>>
}

type SettingsContext = {
  settings: Settings
  setSettings: Dispatch<SetStateAction<Settings>>
}

type WipesContext = {
  wipes: string[]
  setWipes: (value: string[]) => void
}

type AnalyticsContext = {
  trackPage: (name: string, location: string) => void
  trackEvent: (name: string, params?: { [key: string]: unknown }) => void
}

export type AppContext = {
  api: MutableRefObject<Api>
  global: GlobalContext
  session: SessionContext
  itemData: ItemDataContext
  questData: QuestDataContext
  hideoutData: HideoutDataContext
  wipes: WipesContext
  search: SearchContext
  settings: SettingsContext
  analytics: AnalyticsContext
}

type Props = {
  value: AppContext
  children: ReactNode
}

export default ({ value, children }: Props): JSX.Element => (
  <Provider value={value}>{children}</Provider>
)
