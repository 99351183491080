import { Routes, Navigate, Route } from 'react-router-dom'
import { TrackType } from 'src/constants'
import App from './App'
import Profile from './Profile'
import ItemTracker from './ItemTracker'
import QuestTracker from './QuestTracker'
import HideoutTracker from './HideoutTracker'

export default () => {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="" element={<Navigate to="track" replace />} />

        <Route path="track">
          <Route path="" element={<Navigate to="items" replace />} />

          <Route path="keys" element={<ItemTracker type={TrackType.Keys} />} />
          <Route
            path="items"
            element={<ItemTracker type={TrackType.Items} />}
          />
          <Route path="quests" element={<QuestTracker />} />
          <Route path="hideout" element={<HideoutTracker />} />
        </Route>

        <Route path="profile/:username" element={<Profile />} />
      </Route>
    </Routes>
  )
}
