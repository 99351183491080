import tarkov, { DataItem } from 'tarkov-data'
import { TrackType } from 'src/constants'
import { ItemsStorageData } from 'src/types'
import { compare } from './strings'

const isKey = (item: DataItem): boolean => Boolean(item?.isKey)
const isCurrencyItem = (item: DataItem): boolean => Boolean(item?.isCurrency)
const hasActiveQuests = (item: DataItem): boolean =>
  Boolean(item.quests?.length)
export const isQuestComplete = (
  item: DataItem,
  data: ItemsStorageData
): boolean => (data[item.id] ? data[item.id] >= getQuestQuant(item) : false)

export const getFIRQuant = (item: DataItem): number => {
  if (!item.quests?.length) {
    return 0
  }

  return item.quests.reduce(
    (acc, quest) => (acc += quest.fir ? quest.quantity : 0),
    0
  )
}

export const getQuestQuant = (item: DataItem): number => {
  if (!item.quests?.length) {
    return 0
  }

  if (item.isKey) {
    return 1
  }

  return item.quests.reduce((acc, quest) => (acc += quest.quantity), 0)
}

export const sortByWeight = (items: DataItem[]): DataItem[] => {
  return items.sort((a, b) => {
    const aWeight = a?.weight || 0
    const bWeight = b?.weight || 0
    return aWeight - bWeight
  })
}

export const filterByType = (type: TrackType): DataItem[] => {
  let result: DataItem[] = []

  if (type === TrackType.Keys) {
    result = tarkov.items.filter(item => isKey(item))
  } else if (type === TrackType.Items) {
    result = tarkov.items.filter(
      item => !isCurrencyItem(item) && hasActiveQuests(item)
    )
  }

  return result
}

export const filterByName = (data: DataItem[], value: string): DataItem[] => {
  return data.filter(
    item => compare(item.shortName, value) || compare(item.name, value)
  )
}

export const filterOutCompleted = (
  data: DataItem[],
  ItemsStorageData: ItemsStorageData
): DataItem[] => {
  return data.filter(item => !isQuestComplete(item, ItemsStorageData))
}

export const filterOutCollector = (
  data: DataItem[],
  ItemsStorageData: ItemsStorageData
): DataItem[] => {
  return data.filter(item => !item.quests?.find(x => x.name === 'Collector'))
}
