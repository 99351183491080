import React, { useRef, useState, useEffect } from 'react'
import { SnackbarOrigin, SnackbarProvider } from 'notistack'
import { DataItem } from 'tarkov-data'
import { AppContext, DataProvider } from './context'
import {
  initSettings,
  initItemStorageData,
  initQuestStorageData,
  initHideoutStorageData,
} from './helpers/storage'
import { initAnalytics } from './helpers/analytics'
import {
  Session,
  Settings,
  ItemsStorageData,
  QuestsStorageData,
  HideoutStorageData,
} from 'src/types'
import Router from './views'
import Api from './utils/api'

export default () => {
  const [open, setOpen] = useState<boolean>(false)
  const [wipes, setWipes] = useState<string[]>([])
  const [search, setSearch] = useState<string>('')
  const [results, setResults] = useState<DataItem[]>([])
  const [session, setSession] = useState<Session | undefined>(undefined)
  const [settings, setSettings] = useState<Settings>(initSettings())
  const [appDataLoading, setAppDataLoading] = useState<boolean>(true)
  const [newsModalOpen, setNewsModalOpen] = useState<boolean>(false)
  const [itemCollection, setItemCollection] = useState<ItemsStorageData>(
    initItemStorageData(window.location)
  )
  const [questCollection, setQuestCollection] = useState<QuestsStorageData>(
    initQuestStorageData(window.location)
  )
  const [hideoutCollection, setHideoutCollection] =
    useState<HideoutStorageData>(initHideoutStorageData())

  const container: AppContext = {
    api: useRef(new Api()),
    global: {
      newsModalOpen,
      setNewsModalOpen,
      appDataLoading,
      setAppDataLoading,
    },
    session: {
      session,
      setSession,
    },
    itemData: {
      collection: itemCollection,
      setCollection: setItemCollection,
    },
    questData: {
      collection: questCollection,
      setCollection: setQuestCollection,
    },
    hideoutData: {
      collection: hideoutCollection,
      setCollection: setHideoutCollection,
    },
    wipes: {
      wipes,
      setWipes,
    },
    search: {
      open,
      search,
      results,
      setOpen,
      setSearch,
      setResults,
    },
    settings: {
      settings,
      setSettings,
    },
    analytics: {
      trackPage: (title: string, location: string) => {
        if (typeof window.gtag === 'function' && !settings.dev) {
          window.gtag('event', 'page_view', {
            page_title: title,
            page_location: location,
          })
        }
      },
      trackEvent: (
        eventName: string,
        eventParams?: { [key: string]: unknown }
      ) => {
        if (typeof window.gtag === 'function' && !settings.dev) {
          window.gtag('event', eventName, eventParams)
        }
      },
    },
  }

  useEffect(() => {
    const params = new URLSearchParams(document.location.search)
    const removeContextMenuFn = (ev: Event) => ev.preventDefault()
    document.addEventListener('contextmenu', removeContextMenuFn)

    if (params.has('dev') || process.env.NODE_ENV !== 'production') {
      setSettings(state => ({ ...state, dev: true }))
    } else {
      initAnalytics()
    }

    return () => {
      document.removeEventListener('contextmenu', removeContextMenuFn)
    }
    // eslint-disable-next-line
  }, [])

  const anchorOrigin: SnackbarOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  }

  return (
    <DataProvider value={container}>
      <SnackbarProvider
        maxSnack={2}
        anchorOrigin={anchorOrigin}
        autoHideDuration={3000}
        disableWindowBlurListener
        hideIconVariant
      >
        <Router />
      </SnackbarProvider>
    </DataProvider>
  )
}
