import { useContext } from 'react'
import { context } from './context'
import { AppContext } from './Provider'

export default () => {
  const {
    session: { session, setSession },
  } = useContext<AppContext>(context)
  return { session, setSession }
}
