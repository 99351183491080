import { createGlobalStyle } from 'styled-components'
import { sc } from '.'
import fonts from './fonts'

export default createGlobalStyle`
  ${fonts()}

  html, body, input, textarea, select, button {
    color: ${sc.fontColor};
    font-size: ${sc.fontSize};
    font-weight: 300;
    font-family: ${sc.fontFamily};
    text-rendering: optimizeLegibility;

    -webkit-font-smoothing: antialiased;
  }

  body * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  html, body {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    -webkit-touch-callout: none;
    -ms-user-select: none;
    min-height: 100dvh;

    color: ${sc.fontColor};
    background-color: ${sc.background};

    width: 100%;
    padding: 0;
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-weight: 300;
  }

  :focus {
    outline-width: 0;
  }

  a {
    color: ${sc.fontColor};
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  b, strong {
    font-weight: 500;
  }

  .no-scroll {
    scrollbar-width: none;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  .notistack-MuiContent-error {
    color: ${sc.danger};
    border: 1px solid ${sc.danger};
    border-radius: 0;
    background-color: #000;
    letter-spacing: 0.5px;

    &:before {
      content: 'Error: ';
      margin-right: ${sc.spacing(1)};
    }
  }

  .notistack-MuiContent-success {
    color: ${sc.successBright};
    border: 1px solid ${sc.successBright};
    border-radius: 0;
    background-color: #000;
    letter-spacing: 0.5px;
  }
`
