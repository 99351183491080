import tarkov, { HideoutModule } from 'tarkov-data'
import { HideoutStorageData } from 'src/types'

// Aggregates all module levels into a single number (for progress bar)
export const getMaxModuleLevel = (): number => {
  return Object.keys(tarkov.hideout).reduce((acc, key) => {
    const modules = tarkov.hideout[key]
    const lastLevel = modules[modules.length - 1].level
    return acc + lastLevel
  }, 0)
}

// Aggregates all tracked module levels into a single number (for progress bar)
export const getCurrModuleLevel = (data: HideoutStorageData): number => {
  return Object.keys(data).reduce((acc, key) => acc + data[key].level, 0)
}

export const getAllHideoutModules = (): HideoutModule[] => {
  let result: HideoutModule[] = []

  Object.keys(tarkov.hideout).forEach(moduleName => {
    result = result.concat(tarkov.hideout[moduleName])
  })

  return result
}

export const isModuleEmpty = (module: HideoutModule): boolean => {
  return !module.items.length && !module.modules.length
}

export const isModuleUnlocked = (
  module: HideoutModule,
  data: HideoutStorageData
): boolean => {
  for (let i = 0; i < module.modules.length; i++) {
    let curr = module.modules[i]
    let dataModule = data[curr.name]

    if (!dataModule || dataModule.level < curr.level) {
      return false
    }
  }

  return true
}

export const isModuleCompleted = (
  module: HideoutModule,
  data: HideoutStorageData
): boolean => {
  const dataModule = data[module.name]

  if (!dataModule) {
    return false
  }

  return dataModule.level >= module.level
}
