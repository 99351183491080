export enum LocalStorage {
  ItemsDataKey = 'tarkov-tracker-items',
  QuestsDataKey = 'tarkov-tracker-quests',
  HideoutDataKey = 'tarkov-tracker-hideout',
  SettingsKey = 'tarkov-tracker-settings',
}

export enum UrlKey {
  Items = 'i',
  Quests = 'q',
  Hideout = 'h',
}
