import { useContext } from 'react'
import { context } from './context'
import { AppContext } from './Provider'

export default () => {
  const {
    global: {
      newsModalOpen,
      setNewsModalOpen,
      appDataLoading,
      setAppDataLoading,
    },
  } = useContext<AppContext>(context)

  return {
    newsModalOpen,
    setNewsModalOpen,
    appDataLoading,
    setAppDataLoading,
  }
}
