import React, { KeyboardEvent, FocusEvent, ChangeEvent } from 'react'
import styled from 'styled-components'
import { sc } from 'src/styles'

type Props = {
  type?: string
  value: string
  onFocus?: (ev: FocusEvent<HTMLInputElement>) => void
  onChange: (value: string) => void
  onKeyUp?: (ev: KeyboardEvent<HTMLInputElement>) => void
  placeholder?: string
  className?: string
  withClear?: boolean
  autoFocus?: boolean
}

export default ({
  type,
  value,
  placeholder,
  onFocus,
  onChange,
  withClear,
  autoFocus,
  className,
  ...rest
}: Props) => {
  const onClear = () => {
    onChange('')
  }

  const onInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    onChange(ev.target.value)
  }

  const showClear: boolean = Boolean(withClear && value.length)

  return (
    <Container className={className}>
      <Input
        type={type}
        value={value}
        onFocus={onFocus}
        onChange={onInputChange}
        placeholder={placeholder}
        autoFocus={autoFocus}
        {...rest}
      />

      {showClear ? <Clear onClick={onClear}>&#215;</Clear> : null}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: ${sc.spacing(6)};
  background-color: #000;
  border: 1px solid ${sc.secondary};
  transition: all 100ms linear;
`

const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: ${sc.spacing(1)};
  background-color: inherit;
  border: none;
  border-radius: inherit;
  outline: none;
`

const Clear = styled.div`
  position: absolute;
  top: 50%;
  right: ${sc.spacing(1.5)};
  transform: translate(0, -50%);
  width: 20px;
  height: 20px;
  line-height: 20px;

  color: ${sc.secondary};
  text-align: center;
  font-size: 40px;
  cursor: pointer;
  z-index: 10;

  &:hover {
    color: #fff;
  }
`
