import React from 'react'
import styled, { css } from 'styled-components'
import { sc } from 'src/styles'

type Props = {
  size?: 'small' | 'medium' | 'large'
  username: string
  onClick?: () => void
  className?: string
}

export default ({ username, className, size = 'medium', ...rest }: Props) => {
  return (
    <Container $size={size} className={className} {...rest}>
      {username.slice(0, 1)}
    </Container>
  )
}

const Container = styled.div<{
  $size?: string
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #000;
  background-color: ${sc.secondary};
  text-transform: uppercase;
  border: 1px solid #000;
  border-radius: 50%;

  ${props =>
    props.$size === 'small'
      ? css`
          width: ${sc.spacing(3)};
          height: ${sc.spacing(3)};
          font-size: ${sc.fontSizeSmaller};
        `
      : ''}

  ${props =>
    props.$size === 'medium'
      ? css`
          width: ${sc.spacing(4.5)};
          height: ${sc.spacing(4.5)};
        `
      : ''}

  ${props =>
    props.$size === 'large'
      ? css`
          width: ${sc.spacing(6)};
          height: ${sc.spacing(6)};
          font-size: ${sc.fontSizeLarge};
        `
      : ''}
`
