import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { sc } from 'src/styles'
import { useOuterState } from 'src/helpers/hooks'
import { langs } from 'src/constants'
import { useAnalytics } from 'src/context'
import { ReactComponent as _ArrowDown } from './arrow-down.svg'

export default () => {
  const toggleRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLUListElement>(null)
  const { i18n } = useTranslation()
  const { trackEvent } = useAnalytics()
  const { isOpen, setOpen } = useOuterState([toggleRef, dropdownRef])
  const [lang, setLang] = useState<string>(i18n.resolvedLanguage || 'en')
  const onToggleDropdown = () => setOpen(state => !state)
  const onChangeLanguage = (lang: string) => () => {
    i18n.changeLanguage(lang)
    setLang(lang)
    setOpen(false)
    trackEvent('language_change', { lang })
  }

  return (
    <>
      <Container ref={toggleRef} onClick={onToggleDropdown}>
        {lang} <ArrowDown $open={isOpen} />
      </Container>

      {isOpen ? (
        <DropdownMenu ref={dropdownRef}>
          {Object.keys(langs).map(langKey => (
            <li key={`lang-${langKey}`} onClick={onChangeLanguage(langKey)}>
              {langs[langKey].nativeName}
            </li>
          ))}
        </DropdownMenu>
      ) : null}
    </>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${sc.spacing(2)} ${sc.spacing(0)};
  color: ${sc.secondary};
  cursor: pointer;
  text-transform: uppercase;

  @media (max-width: 490px) {
    font-size: ${sc.fontSizeSmallest};
  }
`

const DropdownMenu = styled.ul`
  position: fixed;
  top: ${sc.navHeight}px;
  right: ${sc.spacing(4)};
  box-shadow: ${sc.shadow};
  color: ${sc.secondary};
  background-color: ${sc.background};
  text-align: right;

  & > li {
    cursor: pointer;
    padding: ${sc.spacing(1)} ${sc.spacing(2)};

    &:hover {
      color: ${sc.fontColor};
    }

    &:not(:last-child) {
      border-bottom: 1px solid #000;
    }
  }
`

const ArrowDown = styled(_ArrowDown)<{
  $open: boolean
}>`
  width: 20px;
  height: 20px;
  fill: ${sc.secondary};
  transition: all 100ms linear;

  ${props =>
    props.$open
      ? css`
          transform: rotate(180deg);
        `
      : ''}
`
