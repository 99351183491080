import { DataItem, HideoutModule } from 'tarkov-data'
import { useContext } from 'react'
import { context } from './context'
import { AppContext } from './Provider'
import { syncHideoutStorageData } from 'src/helpers/storage'
import { HideoutStorageData, RustUserHideoutModule } from 'src/types'

export default () => {
  const {
    api,
    session: { session },
    hideoutData: { collection, setCollection },
    analytics: { trackEvent },
  } = useContext<AppContext>(context)

  const isModuleDone = (
    module: HideoutModule,
    data: HideoutStorageData
  ): boolean => {
    for (let i = 0; i < module.items.length; i++) {
      const reqItem = module.items[i]
      const dataCount: number = data[module.name]?.items
        ? data[module.name].items[reqItem.id]
        : 0

      if (!dataCount || dataCount < (reqItem.count || 0)) {
        return false
      }
    }

    return true
  }

  // custom edge-case level updates (i.e. Defective Wall)
  const checkForEdgeCases = (
    updated: HideoutStorageData
  ): HideoutStorageData => {
    if (
      updated['Medstation']?.level >= 1 &&
      updated['Water Collector']?.level >= 1
    ) {
      if (!updated['Defective Wall'] || updated['Defective Wall'].level < 3) {
        updated['Defective Wall'] = {
          level: 3,
          items: {},
        }

        if (session && api.current) {
          const DEFECTIVE_WALL_ID = '637b39f02e873739ec490215'
          api.current.request(
            'POST',
            `/users/hideout/${DEFECTIVE_WALL_ID}/complete`,
            {
              body: {
                level: 3,
              },
            }
          )
        }
      }
    }

    return updated
  }

  return {
    data: collection,

    set: (data: HideoutStorageData) => {
      setCollection(data)
    },

    sync: (data: HideoutStorageData) => {
      setCollection(data)
      syncHideoutStorageData(data)
    },

    trackItem: (item: DataItem, module: HideoutModule) => {
      let updated = { ...collection }

      if (!updated[module.name]) {
        updated[module.name] = {
          level: 0,
          items: {
            [item.id]: 0,
          },
        }
      }

      if (!updated[module.name].items) {
        updated[module.name].items = {}
      }

      if (!updated[module.name].items[item.id]) {
        updated[module.name].items[item.id] = 0
      }

      if (item.isCurrency && item.count) {
        updated[module.name].items[item.id] = item.count
      } else {
        if (item.count && item.count >= 10) {
          updated[module.name].items[item.id] += 5
        } else if (item.count && item.count >= 5) {
          updated[module.name].items[item.id] += 2
        } else {
          updated[module.name].items[item.id] += 1
        }
      }

      if (isModuleDone(module, updated)) {
        updated[module.name].level += 1
        updated[module.name].items = {}
        updated[module.name].favorite = false
        updated = checkForEdgeCases(updated)

        if (session && api.current) {
          api.current.request('POST', `/users/hideout/${module.id}/complete`, {
            body: {
              level: updated[module.name].level,
            },
          })
        }
      } else {
        if (session && api.current) {
          api.current.request('POST', `/users/hideout/${module.id}/items`, {
            body: {
              item_id: item.id,
              count: updated[module.name].items[item.id] || 0,
            },
          })
        }
      }

      setCollection(updated)
      syncHideoutStorageData(updated)

      trackEvent('track_module_item', {
        item_id: item.id,
        module_name: module.name,
        module_level: module.level,
      })
    },

    resetItem: (item: DataItem, module: HideoutModule) => {
      const updated = { ...collection }

      if (
        updated[module.name] &&
        updated[module.name].items &&
        updated[module.name].items[item.id]
      ) {
        delete updated[module.name].items[item.id]
      }

      setCollection(updated)
      syncHideoutStorageData(updated)

      if (session && api.current) {
        api.current.request(
          'DELETE',
          `/users/hideout/${module.id}/items/${item.id}`
        )
      }

      trackEvent('untrack_module_item', {
        item_id: item.id,
        module_name: module.name,
        module_level: module.level,
      })
    },

    favModule: (module: HideoutModule) => {
      const updated = { ...collection }

      if (!updated[module.name]) {
        updated[module.name] = {
          level: 0,
          items: {},
          favorite: true,
        }
      } else {
        updated[module.name].favorite = !updated[module.name].favorite
      }

      if (session && api.current) {
        api.current.request('PUT', `/users/hideout/${module.id}`, {
          body: {
            favorite: updated[module.name].favorite,
          },
        })
      }

      setCollection(updated)
      syncHideoutStorageData(updated)
    },

    uncompleteModule: (module: HideoutModule) => {
      const updated = { ...collection }

      updated[module.name] = {
        level: Math.max(module.level - 1, 0),
        items: {},
      }

      // TODO: not needed?
      // POST /users/hideout/{id}/uncomplete

      setCollection(updated)
      syncHideoutStorageData(updated)

      trackEvent('untrack_module', {
        module_name: module.name,
        module_level: module.level,
      })
    },

    merge: (data: RustUserHideoutModule[]) => {
      const updated: HideoutStorageData = { ...collection }

      // TODO: merge items if level is the same?
      data.forEach(dataModule => {
        updated[dataModule.name] = {
          level: dataModule.level,
          items: dataModule.items,
          favorite: dataModule.favorite,
        }
      })

      setCollection(updated)
    },

    reset: () => {
      setCollection({})
      syncHideoutStorageData({})
      trackEvent('reset_all_hideout')

      if (session && api.current) {
        api.current.request('DELETE', `/users/hideout`)
      }
    },
  }
}
