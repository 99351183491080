import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { sc } from 'src/styles'
import { Modal, Button } from '..'

type Props = {
  isOpen: boolean
  setOpen: (state: boolean) => void
  title?: string
  body: string | JSX.Element
  onAccept: () => void
  onCancel: () => void
}

export default ({
  isOpen,
  setOpen,
  title,
  body,
  onAccept,
  onCancel,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Container isOpen={isOpen} setOpen={setOpen} title={title}>
      <Body>{body}</Body>

      <Actions>
        <Button onClick={onAccept}>{t('accept')}</Button>
        <Button onClick={onCancel}>{t('cancel')}</Button>
      </Actions>
    </Container>
  )
}

const Container = styled(Modal)`
  width: ${sc.spacing(60)};

  & > section {
    gap: 0;
    padding: 0;
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sc.spacing(2)};
  padding: ${sc.spacing(2)};
  text-align: center;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: ${sc.spacing(2)};
  justify-content: center;
  padding: ${sc.spacing(2)};
`
